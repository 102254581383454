import React from "react";
import { Link } from "gatsby";
import "add-to-calendar-button";
import moment from "moment-timezone";

const TrustDownloadPdf = ({ section }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const LinkReturnHandler = ({ link, text }) => {
    if (link?.indexOf("https:") !== -1 || link?.indexOf("https:") !== -1) {
      return (
        <a
          className="exlink text-lg mb-2 text-left font-bold w-100 lg:w-9/12 pt-1 underline hover:no-underline"
          target="_blank"
          rel="noopener noreferrer"
          href={link}
        >
          {text || ""}
        </a>
      );
    } else {
      return (
        <Link
          to={link}
          className="text-lg mb-2 text-left font-bold w-100 lg:w-9/12 pt-1 underline hover:no-underline"
        >
          {text || ""}
        </Link>
      );
    }
  };
  const ButtonReturnHandler = ({ link, text }) => {
    if (
      link?.indexOf("https:") !== -1 ||
      link?.indexOf("https:") !== -1 ||
      section?.newTab === true
    ) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          className="exlink w-1/2 text-white text-center hover:bg-black bg-primary-red transition duration-300 ease-in-out px-6 py-3  lg:px-2 lg:py-3 rounded-full cursor-pointer"
        >
          {text || ""}
        </a>
      );
    } else {
      return (
        <Link
          className="w-1/2 text-white text-center hover:bg-black bg-primary-red transition duration-300 ease-in-out px-6 py-3  lg:px-2 lg:py-3 rounded-full cursor-pointer"
          to={link}
        >
          {text || ""}
        </Link>
      );
    }
  };
  return (
    <>
      <div className="main max-w-4xl mx-auto flex flex-col lg:flex-row justify-between px-5 lg:px-0  pt-16 lg:pt-24">
        <div className="w-full lg:w-45">
          <p className=" text-3xl font-bold">{section?.heading}</p>
          <p className="text-xl pt-8">{section?.leftDescription}</p>
        </div>
        <div className="w-full lg:w-2/5 flex flex-col pl-0 lg:pl-22 pt-12 lg:pt-0">
          <p className=" text-xl pb-8 font-bold">{section?.rightHeading}</p>
          <ul className="list-disc pl-8 text-lg">
            {section?.includeInFolder?.map((single, index) => (
              <li className="pb-3" key={index}>
                {single?.includeItems}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="max-w-4xl mx-auto mt-16 border-dark-beige border-t border-opacity-75">
        <div className="flex justify-between">
          <div className="w-2/5 hidden lg:block" />
          <div className="border-dark-beige border-l border-opacity-75 lg:w-3/5 w-100 pl-5 pr-5 lg:pl-14 lg:pr-0">
            <p className="text-xl pt-12 border-dark-beige lg:border-b border-none border-opacity-75 pb-6">
              {section?.bottomDescription}
            </p>
            <div className="flex flex-col items-start">
              {!section?.HideUnderlinedText && (
                <LinkReturnHandler
                  link={section?.url}
                  text={section?.underlinedText}
                />
              )}
              {!section?.HideSecondUnderlinedText && (
                <LinkReturnHandler
                  link={section?.urlSecond}
                  text={section?.underlinedTextSecond}
                />
              )}
            </div>
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center w-full lg:w-9/12 pt-10 mb-8">
              {section?.addToCalendar?.showAddToCalendar ? (
                <div>
                  <add-to-calendar-button
                    name={section?.addToCalendar?.eventTitle || ""}
                    description={section?.addToCalendar?.description || ""}
                    startDate={
                      section?.addToCalendar?.startDateTime &&
                      moment(section?.addToCalendar?.startDateTime).format(
                        "YYYY-MM-DD"
                      )
                    }
                    startTime={
                      section?.addToCalendar?.startDateTime &&
                      moment(section?.addToCalendar?.startDateTime).format(
                        "HH:mm a"
                      )
                    }
                    endDate={
                      section?.addToCalendar?.endDateTime &&
                      moment(section?.addToCalendar?.endDateTime).format(
                        "YYYY-MM-DD"
                      )
                    }
                    endTime={
                      section?.addToCalendar?.endDateTime &&
                      moment(section?.addToCalendar?.endDateTime).format(
                        "HH:mm a"
                      )
                    }
                    location={section?.addToCalendar?.location || ""}
                    options="['Apple','Google','iCal','Microsoft365','Outlook.com','Yahoo']"
                    trigger="click"
                    inline
                    listStyle="modal"
                    iCalFileName="Reminder-Event"
                    hideBackground
                    timeZone={timezone}
                    buttonStyle="round"
                    hideCheckmark
                    styleLight="--btn-background: #000000; --btn-background-hover: #db3f32; --btn-shadow:none; --btn-shadow-hover:none; --btn-text:#ffffff;"
                    lightMode="bodyScheme"
                    size="5"
                  >
                    {" "}
                    Add{" "}
                  </add-to-calendar-button>
                </div>
              ) : (
                <ButtonReturnHandler
                  text={section?.btnText}
                  link={section?.btnLink}
                />
              )}
              <a
                className="exlink w-full lg:w-2/5 text-left lg:text-center text-dark-beige underline hover:no-underline mt-8 lg:mt-0 cursor-pointer"
                target="_blank"
                href={section?.reportLink}
              >
                {section?.reportText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TrustDownloadPdf;
