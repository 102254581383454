import React from "react";
import { Link } from "gatsby";
const PressAndMediaHeader = ({ section }) => {
  const pressAndMediaActiveHandler = (value) => {
    return typeof window !== "undefined" &&
      window.location.pathname.indexOf(value) !== -1
      ? "underline text-black"
      : "no-underline text-dark-beige ";
  };

  return (
    <>
      <div className="container mx-auto mx-auto lg:px-17">
        <div className="border-b-2 border-light-yellow border-opacity-30 w-10/12 mx-auto">
          <h1 className="text-2.8 leading-54 font-bold text-center">
            {section?.heading || ""}
          </h1>
          <div className="flex justify-center container mx-auto py-16">
            <ul className="flex flex-wrap flex-col lg:flex-row text-center">
              {section?.pressLinks?.map((link, linIndex) => {
                return (
                  <Link to={link?.link} key={linIndex}>
                    <li
                      className={`cursor-pointer mb-6 ${
                        linIndex === section?.pressLinks?.length - 1
                          ? "mr-0"
                          : "mr-0 lg:mr-12"
                      } ${pressAndMediaActiveHandler(link?.link)}`}
                    >
                      {link?.title || ""}
                    </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default PressAndMediaHeader;
