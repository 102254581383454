import React from "react";
import { generateImageUrl } from "../../../sanity";
import { Link } from "gatsby";

const CourseHero = ({ section, header_options }) => {
  let Url =
    section.mainBg.asset._ref &&
    generateImageUrl(section.mainBg.asset._ref).url();

  return (
      <div
        className={`flex items-end px-4 relative h-full ${
          header_options?.bg_transparent ? "-top-40" : "top-0"
        } lg:px-0`}
        style={{
          backgroundImage: `url(${Url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "836px",
        }}
      >
        <div className="container lg:px-17 mx-auto">
          <div className="mb-14 w-full lg:w-4/5">
            <p className="font-bold text-4.5 text-white leading:60 font-Dazzed tracking-wide lg:text-7xl lg:leading-80">
              {section.heading}
            </p>
            <div className="flex flex-col mt-8 lg:flex-row lg:mt-8">
              {section?.leftButtonLink?.indexOf("http") !== -1 ? (
                <a
                  href={section.leftButtonLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="w-full ease-in-out transition duration-300 text-base text-black hover:text-white rounded border border-2 bg-white hover:bg-transparent hover:text-black py-3 px-6"
                    type="button"
                  >
                    {section.leftButton}
                  </button>
                </a>
              ) : (
                <Link to={section.leftButtonLink}>
                  <button
                    className="w-full ease-in-out transition duration-300 text-base text-black hover:text-white rounded border border-2 bg-white hover:bg-transparent hover:text-black py-3 px-6"
                    type="button"
                  >
                    {section.leftButton}
                  </button>
                </Link>
              )}

              <Link to={section.RightButtonLink}>
                <button
                  className="w-full ml-0 ease-in-out transition duration-300 text-base hover:text-black text-white rounded hover:bg-white  bg-transparent hover:text-black border border-2 py-3 px-6 mt-2 lg:mt-0 lg:ml-3"
                  type="button"
                >
                  {section.RightButton}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
  );
};
export default CourseHero;
