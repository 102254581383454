import React from "react";
import DecoratorImage from "../../../../assets/images/decoratorSevenSecond.png";
const DecoratorUnderstandingTrust = () => {
  return (
    <div>
      <img className="mx-auto" width="100%" src={DecoratorImage} alt="decorator" loading="lazy" />
    </div>
  );
};
export default DecoratorUnderstandingTrust;
