import React from "react";
const BuyTheBookListItems = ({ name, url }) => {
  return (
    <>
      <li>
        <a target="_blank" rel="noopener noreferrer" href={url} className="text-primary-red underline cursor-pointer hover:no-underline text-left text-lg">
          {name}
        </a>
      </li>
    </>
  );
};
export default BuyTheBookListItems;
