import React from "react";
import SignUpSecond from "../../forms/signUpWithRightButton";
const AboutForm = ({ section }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="w-full lg:w-5/12 mx-auto px-12 lg:px-5 xl:px-0">
          <h1 className="text-4xl lg:text-2xl leading-42 lg:leading-32 font-black lg:font-bold text-left lg:text-center py-6">{section?.title || ""}</h1>
          <div className="w-full lg:w-full mx-auto">
            <SignUpSecond name="newsletter"/>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutForm;
