import React from "react";
import FullMediaKit from "../brandAssetsFullMediaKit";
import LogoAndWordMark from "../brandAssetsLogoAndWordMark";
import BookMockUps from "../brandAssetsBookMockUps";
import WebsiteScreenshots from "../brandAssetsWebsiteScreenshots";
import BrandAssetsPhotography from "../brandAssetsPhotography";
import SizzleReel from "../brandAssetsSizzelReel";

const BrandAssetsBody = ({ section }) => {
  const renderSections = (mediaSection, index) => {
    switch (mediaSection?.layoutType) {
      case "logos_&_wordmark":
        return <LogoAndWordMark key={index} content={mediaSection} />;
      case "book_mock_ups":
        return <BookMockUps key={index} content={mediaSection} />;
      case "website_screenshots":
        return <WebsiteScreenshots key={index} content={mediaSection} />;
      case "photography":
        return <BrandAssetsPhotography key={index} content={mediaSection} />;
      case "sizzle_Reel":
        return <SizzleReel key={index} content={mediaSection} />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <FullMediaKit section={section} />
      {section?.items?.length ? (
        <div>
          {section?.items?.map((mediaSection, index) =>
            renderSections(mediaSection, index)
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default BrandAssetsBody;
