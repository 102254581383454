import React from "react";
import Star from "../../star";
const RatingTempleteFour = ({ section: { reviews } }) => {
  const startsHandler = (rating) => {
    let count = [];
    for (let i = 0; i <= rating - 1; i++) {
      count.push(i);
    }
    return count;
  };
  return (
    <>
      <div className="container mx-auto">
        <div className="w-full lg:w-3/4 mx-auto px-5 lg:px-0">
          {reviews &&
            reviews?.map((review, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-center">
                    {startsHandler(review?.rating).map((star, starIndex) => {
                      return <Star key={starIndex} />;
                    })}
                  </div>
                  <h1 className="text-2xl leading-32 lg:text-4xl lg:leading-50 font-bold text-center py-5">"{review?.copy}"</h1>
                  <div className="text-center">
                    <span className="text-lg font-semibold text-center">{review?.author}, </span>
                    <span className="text-lg text-center italic ">{review?.bio || ""}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default RatingTempleteFour;
