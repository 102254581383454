import React from "react";
const AttributeList = ({ section }) => {
  
  return (
    <>
      <div className="container mx-auto px-5 lg:px-16 ">
        {section?.attributesList?.map((single, index) => (
          <div
            className={`w-86 lg:pb-0 pb-20 ${
              index === section?.attributesList?.length - 1
                ? " border-b-none"
                : "border-light-yellow border-opacity-30 border-b-2"
            }`}
            key={index}
          >
            <div className="text-light-black">
              <div className="flex justify-between">
                <div
                  className={`text-4xl text-dark-beige font-semibold pb-4 lg:pb-16 border-light-yellow border-opacity-30 border-r-none lg:border-r-2 w-full lg:w-1/2 ${
                    index === 0 ? "pt-0" : "pt-16"
                  }`}
                >
                  {single?.title}
                </div>
                <div className="ml-16 text-2xl w-1/2 hidden lg:block"></div>
              </div>
              {single?.attributeItems?.map((each, eachIndex) => (
                <div
                  className=" flex flex-col lg:flex-row justify-between"
                  key={eachIndex}
                >
                  <div className="text-2xl lg:text-3xl font-semibold lg:pt-0 pt-7 pb-2 lg:pb-16 border-light-yellow border-opacity-30 border-r-none lg:border-r-2 w-full lg:w-1/2">
                    {each?.title}
                  </div>
                  <div className="ml-0 lg:ml-16 text-xl lg:text-2xl w-full lg:w-1/2 pb-2 lg:pb-16">
                    {each?.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default AttributeList;
