import React from "react";
import { generateImageUrl, getImageSize } from "../../../sanity";
import Decorator from "../decorators/decoratorTrust";
import SingleDecoratorTrust from "../decorators/singleDecoratorTrust";
import DecoratorMobile from "../decorators/decoratorTrustMobile";
import Slider from "@ant-design/react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TrustExerciseFeaturedLogos = ({ section }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 4000,
    arrows: false,
  };
  return (
    <>
      <div className="relative">
        <div className="max-w-6xl mx-auto lg:px-16 relative">
          <div className="flex justify-center">
            <h1 className="font-bold text-2xl pt-4 pb-2">{section?.heading}</h1>
          </div>
          <div className="hidden lg:block">
            <div className="flex flex-wrap items-center justify-center w-full pb-16 px-2 lg:px-0 mx-auto trust-page-exercise">
              {section?.logos?.map((logo, index) => {
                let url = generateImageUrl(logo?.image?.asset?._ref).url();
                const { width } = getImageSize(logo?.image?.asset?._ref);
                const multiplier = logo?.is_retina ? 0.3 : 1;
                return (
                  <img
                    className={`${
                      index === section?.logos?.length - 1
                        ? "mr-0 h-fit"
                        : "h-fit mr-4 lg:mr-10"
                    }`}
                    key={index}
                    src={url}
                    alt={logo?.alt}
                    loading="lazy"
                    width={width * multiplier}
                  />
                );
              })}
            </div>
          </div>

          {/* for mobile */}

          <div className="pb-16 px-2 lg:px-0 mx-auto block lg:hidden services-slider">
            <Slider {...settings}>
              {section?.logos?.map((item, index) => {
                let url = generateImageUrl(item?.image?.asset?._ref).url();
                const { width } = getImageSize(item?.image?.asset?._ref);
                const multiplier = item?.is_retina ? 0.3 : 3;
                return (

                  <div  key={index} className="flex justify-center">
                    <img
                      src={url}
                      alt={item?.alt}
                      loading="lazy"
                      width={width * multiplier}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        {!section?.hideDecorator && (
          <>
            <div className="hidden lg:block">
              <Decorator />
            </div>
            <div className="block lg:hidden">
              <DecoratorMobile />
            </div>
            <div className="absolute -top-28 -left-20 hidden lg:block z-10">
              <SingleDecoratorTrust />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default TrustExerciseFeaturedLogos;
