import React from "react";
import { generateImageUrl } from "../../../sanity";
const TrustExerciseImage = ({ section }) => {
  let bgImage = generateImageUrl(section?.image?.image?.asset?._ref).url();
  return (
    <>
      <div className="relative flex justify-center items-center">
        <div
          className="w-full h-52 lg:h-37"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "100%",
            with: "100%",
          }}
        ></div>
        
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <p className="text-white lg:text-4xl text-2xl font-bold absolute">
          {section?.title}
        </p>
      </div>
    </>
  );
};
export default TrustExerciseImage;
