import React from "react";
import { generateImageUrl } from "../../../sanity";
const TrustPageHero = ({ section }) => {
  let bgImage =
    section?.image?.image?.asset?._ref &&
    generateImageUrl(section?.image?.image?.asset?._ref).url();
  return (
    <>
      <div
        className="w-full h-52 lg:h-96 hidden lg:block"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100%",
          with: "100%",
        }}
      ></div>
    </>
  );
};
export default TrustPageHero;
