import React from "react";
import Decorator from "../../../../assets/images/trustDecoratorMobile.png";
const DecoratorTrustMobile = () => {
  return (
    <>
      <img
        className="w-full mx-auto"
        src={Decorator}
        alt="decorator"
        loading="lazy"
      />
    </>
  );
};
export default DecoratorTrustMobile;
