import React from "react";
import Polygon from "../../../assets/images/polygon.png";
import DoubleMan from "../../../assets/images/doubleMan.png";
import Spacer from "../../spacer";
import Decorator from "../decorators/decoratorFive";
import { Link } from "gatsby";
const HeroSection = ({ section }) => {
  const LinkReturnHandler = ({ link, text }) => {
    if (link?.indexOf("http:") !== -1 || link?.indexOf("https:") !== -1) {
      return (
        <a
          className="text-xs lg:text-lg  cursor-pointer hover:no-underline hover:text-black hover:text-black underline text-primary-red"
          target="_blank"
          rel="noopener noreferrer"
          href={link}
        >
          {text || ""}
        </a>
      );
    } else {
      return (
        <Link
          to={link}
          className="text-lg mb-2 text-left font-bold w-100 lg:w-9/12 pt-1 underline hover:no-underline"
        >
          {text || ""}
        </Link>
      );
    }
  };

  return (
    <>
      <div className="w-full relative">
        <div className="container mx-auto flex px-7 lg:px-17">
          <div className="lg:pr-96">
            <h1 className="text-4xl lg:text-7xl leadgin-64 lg:leading-93 font-black pt-12 lg:pt-28 pb-12">
              {section?.title}
            </h1>
            <div className="flex  lg:items-center flex-col lg:flex-row">
              <Link to={section?.button?.btnLink}>
                <button className="text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-4 py-3  lg:px-2 xl:px-7 lg:py-4 rounded-full cursor-pointer">
                  {section?.button?.btnText}
                </button>
              </Link>
              {section?.headerLinkText && section?.headerLink && (
                <span className="cursor-pointer lg:pl-5 mt-2 lg:mt-0">
                  <LinkReturnHandler
                    link={section?.headerLink}
                    text={section?.headerLinkText}
                  />
                </span>
              )}
            </div>
            <Spacer large />
            <Spacer medium />
          </div>
          <div className="flex items-end absolute right-0 top-12 lg:top-0">
            <div className="pr-1 lg:pr-24 pb-8 lg:pb-1">
              <img src={DoubleMan} alt="menShadow" loading="lazy" />
            </div>
            <div>
              <img src={Polygon} alt="Polygon" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
      <Decorator />
    </>
  );
};
export default HeroSection;
