import React from "react";
import BuyTheBookListItems from "../buyTheBookListItems";
const BuyTheBookListHardCover = ({ item }) => {
  return (
    <>
      <div className="container mx-auto">
        <h1 className="font-bold mb-3 text-lg ">{item?.variantName}</h1>
        <ul className="grid grid-cols-2 gap-y-4 lg:gap-y-1 gap-x-4 lg:gap-x-6">
          {item?.resellers?.map((seller, index) => {
            return <BuyTheBookListItems key={index} name={seller?.name} url={seller?.href} />;
          })}
        </ul>
      </div>
    </>
  );
};
export default BuyTheBookListHardCover;
