import React from "react";
import Decorator from "../../../../assets/images/decoratorTrust.png";
const DecoratorTrust = () => {
  return (
    <>
      <img className="mx-auto" src={Decorator} alt="decorator" loading="lazy" />
    </>
  );
};
export default DecoratorTrust;
