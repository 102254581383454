import React from "react";
import { Widget } from "@typeform/embed-react";
import { paramsReturnHandler } from "../../../utils";

const TypeFormLoader = ({ section, location }) => {
  let hiddenItems = {};
  if (location?.search) {
    hiddenItems = paramsReturnHandler(location.search);
  }

  return (
    <div>
      <div className="container mx-auto">
        <Widget
          hidden={hiddenItems}
          id={section?.typeformId}
          style={{
            height: section?.heightPx
              ? parseInt(section?.heightPx) + "px"
              : "600px",
          }}
          hideHeaders
          onSubmit={(e) => {
            console.log("formSubmitted", e);
          }}
        />
      </div>
    </div>
  );
};
export default TypeFormLoader;
