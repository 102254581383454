import React, { useEffect, useState } from "react";
import RedButton from "../redButton";
import BlogCard from "../../pageComponents/homePageBlogCard";
import HomePageSingleCard from "../homePageSingleCard";
import Decorator from "../decorators/decoratorSmall";
import Spacer from "../../spacer";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import HomeMobileDecorator from "../../pageComponents/decorators/decoratorMobile";
const AttributeBlog = ({
  allBlogs,
  section: { buttonLink, buttonText, title, description, decoratorHidden },
  serialize,
}) => {
  const [featureBlogs, setFeatureBlogs] = useState([]);
  const [highlighted, setHighlighted] = useState(null);

  useEffect(() => {
    if (allBlogs) {
      let _featuredBlogs = allBlogs?.filter(
        (blog) => blog?.featuredBlog === true
      );
      setFeatureBlogs(_featuredBlogs);
      setHighlighted(allBlogs?.find((blog) => blog?.highlightedBlog === true));
    }
  }, [allBlogs]);
  return (
    <>
      {!decoratorHidden && (
        <div>
          <div className="lg:hidden">
            <HomeMobileDecorator />
          </div>
          <div className="hidden lg:block">
            <Decorator />
          </div>
        </div>
      )}
      <div className="attribute-blog w-full lg:max-w-5xl px-0 lg:px-16 xl:px-0 mx-auto px-5 md:px-11 lg:px-0">
        <BlockContent
          blocks={title}
          serializers={serialize(
            "font-black font-Dazzed leading-50 lg:leading-110 text-5xl lg:text-116",
            true
          )}
        />
        <div className="flex flex-col lg:flex-row justify-between items-center mb-12 lg:mb-0">
          <div className="w-full lg:w-3/4">
            <p className="leading-32 text-2xl py-8 lg:py-12">
              {description || ""}
            </p>
          </div>
          <div className="w-full lg:w-auto mt-8 lg:mt-20 flex lg:justify-center justify-start">
            {buttonText && buttonLink && (
              <Link to={buttonLink}>
                <RedButton>{buttonText}</RedButton>
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-wrap lg:flex-row justify-between pt-12 lg:pt-0">
          {featureBlogs &&
            featureBlogs?.map((card, cardIndex) => {
              return (
                <BlogCard card={card} key={cardIndex} serialize={serialize} />
              );
            })}
        </div>
      </div>
      {highlighted && (
        <div>
          <Spacer section={{ size: "m" }} />
          <div className="hidden lg:block">
            <HomePageSingleCard data={highlighted} serialize={serialize} />
          </div>
        </div>
      )}
    </>
  );
};
export default AttributeBlog;
