import React from "react";
const HtmlEmbed = ({ section }) => {
  return (
    <div className="html-code">
      <div className="container mx-auto">
        <div dangerouslySetInnerHTML={{ __html: section?.code }}></div>
      </div>
    </div>
  );
};
export default HtmlEmbed;
