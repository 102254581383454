import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import "./style.css"
const AssessmentHandleStress = ({ section, serializeContentEditor }) => {
  return (
    <>
      <div className="container mx-auto  lg:px-16 relative px-6 overflow-hidden assesmentHandleStress">
        <h1 className="dark font-Dazzed text-5xl leading-50 lg:text-116 font-black lg:leading-118 pt-12 lg:pt-0 pb-12 lg:pb-24 ">{section?.title}</h1>
        <svg className="absolute top-32 hidden lg:block right-0" viewBox="0 0 1419 447" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle fill="rgba(144,114,54,.06)" cx="220.5" cy="118.5" r="47.5"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="44" cy="168" r="44"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="78" cy="418" r="25"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="339" cy="228" r="29"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="368" cy="118" r="29"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="1255" cy="348" r="29"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="1304.5" cy="48.5" r="20.5"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="1337" cy="168" r="82"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="1073.5" cy="248.5" r="81.5"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="779" cy="228" r="17"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="480.5" cy="408.5" r="38.5"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="561" cy="128" r="55"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="695.5" cy="188.5" r="39.5"></circle>
          <circle fill="rgba(144,114,54,.06)" cx="856" cy="68" r="68"></circle>
        </svg>
        <div className="w-full xl:w-2/3	xl:pl-36 xl:pr-24">
          <BlockContent blocks={section.content} serializers={serializeContentEditor()} />
        </div>
      </div>
    </>
  );
};
export default AssessmentHandleStress;
