import React from "react";
import { generateImageUrl } from "../../../sanity";

const LogoAndWordMark = ({ content }) => {
  return (
    <>
      <div className="container mx-auto lg:px-17">
        <div className="border-b-2 border-light-yellow border-opacity-30">
          <h1 className="text-2.7 leadgin-54 font-bold text-center pt-28 lg:pt-24 pb-4 lg:pb-8 px-4 lg:px-0">
            {content?.title}
          </h1>
          <div className="flex flex-col flex-wrap lg:flex-row  justify-between">
            {content?.images?.map((each, index) => (
              <div className="w-full lg:w-45 mt-15 px-4 lg:px-0" key={index}>
                <div
                  className={`w-full border-2 border-black h-44 lg:h-56 flex justify-center items-center ${
                    each?.backgroundColor === "black"
                      ? "bg-light-black"
                      : "bg-transparent"
                  }`}
                >
                  <img
                    loading="lazy"
                    src={generateImageUrl(each?.image?.asset?._ref).url() || ""}
                    alt="redLogo"
                  />
                </div>
                <p className="text-black text-2xl py-4 pr-0 lg:pr-4">
                  {each?.title}
                </p>
                <p className="text-dark-beige text-xl">{each?.imageFormats}</p>
              </div>
            ))}
          </div>
          <div className="flex justify-center py-12">
            <a
              className="text-primary-red underline hover:text-black hover:no-underline cursor-pointer w-max"
              target="_blank"
              rel="noopener noreferrer"
              href={`${content?.linkUrl}`}
            >
              {content?.linkText}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default LogoAndWordMark;
