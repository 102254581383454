import React from "react";
import { generateImageUrl } from "../../../sanity";
import { Link } from "gatsby";

const ServicesDiscover = ({ section }) => {
  return (
    <div>
      {section?.servicesteamlist?.map((teams, index) => (
        <div
          key={index}
          className={`container mx-auto flex items-center justify-between flex-col-reverse lg:flex-row px-3 lg:px-17 ${
            index % 2 === 1 ? "lg:flex-row-reverse mt-20" : ""
          }`}
        >
          <img
            className="w-full lg:w-34"
            src={generateImageUrl(teams?.discoverImage?.asset?._ref).url()}
            alt={teams.discoverImageAlt}
            loading="lazy"
          />
          <div
            className={`w-full lg:w-56  ${
              index % 2 === 1
                ? "lg:pl-40 pl-0 lg:pr-0 pr-4"
                : "lg:pr-48 pr-4 pl-0"
            }`}
          >
            <h1 className="font-semibold leading-30 tracking-wide font-Dazzed-medium text-4.4 lg:leading-40 lg:text-4xl">
              {teams.heading}
            </h1>
            <p className="text-lg font-medium font-Brother leading-20 pt-6 pb-10 lg:text-xl">
              {teams.description}
            </p>
            <Link to={teams.ButtonLink}>
              <button
                className="text-base bg-dark-beige hover:text-white ease-in-out transition duration-300 px-6 py-3 rounded-full mt-4 mb-10 lg:mb-0 lg:mt-0"
                type="button"
              >
                {teams.button}
              </button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ServicesDiscover;
