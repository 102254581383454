import React from "react";
import { generateImageUrl } from "../../../sanity";
import { dateFormatHandler } from "../../../utils";
import { Link } from "gatsby";
const HomePageSingleCard = ({ data }) => {
  return (
    <>
      <div className="w-full lg:max-w-5xl mx-auto px-0 lg:px-16 xl:px-0 flex">
        <div className="px-3 w-3/4 pt-16 pb-8 leading-54 px-16 bg-extra-dark-gray rounded-r-none rounded-lg">
          <Link to={`/blog/${data?.slug?.current}`}>
            <h1 className="leading-50 text-5xl font-bold text-Dazzed pt-12">{data?.title}</h1>
          </Link>
          <p className="leading-21 text-base pt-12">{data?.shortDesc}</p>

          <div className="flex justify-between pt-8 pb-8">
            <div>
              <span className="leading-31 text-sm">{dateFormatHandler(data?.date)}</span>
            </div>
            <div>
              <span>
                <Link to={`/blog/${data?.slug?.current}`} className="text-primary-red cursor-pointer hover:text-black font-semibold">
                  Read More
                </Link>
              </span>
            </div>
          </div>
        </div>
        <Link
          aria-label={data?.title || ""}
          style={{
            backgroundImage: `url(${generateImageUrl(data?.hero_image?.asset?._ref).url()})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            minHeight: "100px",
          }}
          to={`/blog/${data?.slug?.current}`}
          className="w-3/5 rounded-l-none rounded-lg bg-extra-dark-gray"
        ></Link>
      </div>
    </>
  );
};
export default HomePageSingleCard;
