import React from "react";
import SmilingMen from "../../../assets/images/about-rich-men-smiling.png";
import FacebookIcon from "../../icons/facebook";
import InstagramIcon from "../../icons/instagram";
import LinkedInIcon from "../../icons/linkedIn";
import TwitterIcon from "../../icons/twitter";
import BlockContent from "@sanity/block-content-to-react";
import "./style.css";
const AboutRichHero = ({ serializeContentEditor, section: { title, content, socialLinks } }) => {

  return (
    <>
      <div className="py-12"></div>
      {/* About Rich page hero section */}
      <div className="flex flex-col lg:flex-row container mx-auto px-0 lg:px-17 justify-end items relative authorHero">
        <div className="w-3/4 lg:w-2/5 ml-20 lg:ml-64  px-4 lg:px-0">
          {" "}
          <div className="absolute left-0 top-36 pl-5 lg:pl-16 lg:top-8 xl:top-40">
            <BlockContent blocks={title?.content} serializers={serializeContentEditor("font-black text-6xl lg:text-116 lg:leading-146 font-Dazzed md:leading-80", true)} />
          </div>
          <div>
            <img src={SmilingMen} loading="lazy" alt="smiling men" />
          </div>
        </div>
        <div className="pl-8 pr-4 lg:pr-0 xl:pr-16 w-full lg:w-3/5 px-4 pt-8 lg:pt-0">
          <BlockContent blocks={content?.content} serializers={serializeContentEditor()} />
          <div className="flex">
            {socialLinks?.facebook && (
              <a href={socialLinks?.facebook} target="_blank" className="text-primary-red mr-4" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
            )}
            {socialLinks?.instagram && (
              <a  href={socialLinks?.instagram} target="_blank" className="text-primary-red mr-4" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
            )}
            {socialLinks?.linkedin && (
              <a  href={socialLinks?.linkedin} target="_blank" className="text-primary-red mr-4" rel="noopener noreferrer">
                <LinkedInIcon />
              </a>
            )}
            {socialLinks?.twitter && (
              <a href={socialLinks?.twitter} target="_blank" className="text-primary-red mr-4" rel="noopener noreferrer">
                <TwitterIcon />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutRichHero;
