import React from "react";
import RedButton from "../redButton";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import "./style.css";
const HomePageDescripiton = ({ section, serializeContentEditor }) => {
  return (
    <>
      <div className="w-full lg:max-w-7xl mx-auto px-5 md:px-11 lg:px-0 flex flex-col lg:flex-row authorContent">
        <div className="w-full lg:w-full pl-0 lg:pl-72 left-side">
          <BlockContent
            blocks={section?.content}
            serializers={serializeContentEditor()}
          />
        </div>
        <div className="w-full lg:w-3/5 pl-0 pr-0 lg:pl-48 lg:pr-16 pt-8 lg:pt-0 right-side">
          <p className="text-2xl leading-32 font-bold pb-9">
            {section?.sideContent?.heading || ""}
          </p>
          {section?.sideContent?.button?.buttonLink &&
            section?.sideContent?.button?.buttonText && (
              <Link to={section?.sideContent?.button?.buttonLink}>
                <RedButton>
                  {section?.sideContent?.button?.buttonText}
                </RedButton>
              </Link>
            )}
        </div>
      </div>
    </>
  );
};
export default HomePageDescripiton;
