import React from "react";
const DecoratorSix = () => {
  return (
    <>
      <svg
        className=""
        viewBox="0 0 1419 447"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle fill="rgba(144,114,54,.06)" cx="220.5" cy="118.5" r="47.5"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="44" cy="168" r="44"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="78" cy="418" r="25"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="339" cy="228" r="29"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="368" cy="118" r="29"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="1255" cy="348" r="29"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="1304.5" cy="48.5" r="20.5"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="1337" cy="168" r="82"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="1073.5" cy="248.5" r="81.5"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="779" cy="228" r="17"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="480.5" cy="408.5" r="38.5"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="561" cy="128" r="55"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="695.5" cy="188.5" r="39.5"></circle>
        <circle fill="rgba(144,114,54,.06)" cx="856" cy="68" r="68"></circle>
      </svg>
    </>
  );
};
export default DecoratorSix;
