import React, { useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { useWindowSize } from "../../../windowSize";
const WorkbookPricing = ({ section }) => {
  let _windowSize = useWindowSize();
  let largeScreen =
    typeof window !== "undefined" &&
    _windowSize?.width &&
    _windowSize?.width > 1023;
  const [height, setHeight] = useState({ first: 0, second: 0, third: 0 });
  const [headingHeight, setHeightHeading] = useState({
    first: 0,
    second: 0,
    third: 0,
  });
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const refHeading = useRef(null);
  const refHeading2 = useRef(null);
  const refHeading3 = useRef(null);
  const refHeading4 = useRef(null);
  const containsNumbers = (str) => {
    return /\d/.test(str);
  };
  useEffect(() => {
    setHeight({
      first: ref?.current?.clientHeight,
      second: ref2?.current?.clientHeight,
      third: ref3?.current?.clientHeight,
    });
    setHeightHeading({
      first: refHeading?.current?.clientHeight,
      second: refHeading2?.current?.clientHeight,
      third: refHeading3?.current?.clientHeight,
      fourth: refHeading4?.current?.clientHeight,
    });
  }, []);

  let _array = Object.values(height);
  let maxValue = largeScreen
    ? Math.max(..._array).toString() + "px"
    : "max-content";
  let _headingArray = Object.values(headingHeight);
  let maxValueHeading = largeScreen
    ? Math.max(..._headingArray).toString() + "px"
    : "max-content";

  let maxTitle = 0;

  section?.pricingPackages?.allPackageItems?.forEach((item) => {
    if (maxTitle < item?.title?.length) {
      maxTitle = item?.title?.length;
    }
  });
  let _new = maxTitle < 40 ? maxTitle + 8 : maxTitle;
  let _maxTitleValue = largeScreen ? _new.toString() + "px" : "max-content";

  return (
    <>
      <div id="workbookPricing" className="container mx-auto px-8 lg:px-0">
        <h1 className="text-largexl font-bold text-center">
          {section?.leadHeading}
        </h1>
        <p className="text-2xl leading-24 lg:leading-42 text-center text-light-black mb-16 pt-3 lg:pt-0">
          {section?.copy}
        </p>
        <div className="flex flex-col lg:flex-row px-0 lg:px-24">
          <div className="w-full lg:w-1/4 px-2 hidden lg:block mb-8 lg:mb-0">
            <div ref={refHeading} style={{ minHeight: maxValueHeading }}>
              <h1 className="text-4xl leading-48 text-light-black font-black pt-24">
                Pricing Packages
              </h1>
              <p className="text-lg leading-24 text-light-black pt-5 pb-12 opacity-0 lg:min-h-9">
                Choose one of any of the three Attribute workbooks: Grit, Mental
                Acuity, Drive
              </p>
              <button className="text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-4 py-3  lg:px-2 xl:px-7 lg:py-4 rounded-full cursor-pointer opacity-0">
                Checkout
              </button>
            </div>
            <div style={{ minHeight: maxValue }} className="hidden md:block">
              <h1 className={`text-4xl leading-48 font-black pt-10 opacity-0`}>
                $4.99
              </h1>
              <p className="text-dark-beige font-normal text-lg pt-1 opacity-0">
                /per workbook
              </p>
            </div>

            <div className="mb-7" style={{ minHeight: _maxTitleValue }}>
              <h1 className="text-lg leading-24 font-bold text-black-two pt-8">
                WHAT’S INCLUDED
              </h1>
            </div>
            <div>
              {section?.pricingPackages?.allPackageItems &&
                section?.pricingPackages?.allPackageItems.map(
                  (single, index) => {
                    return (
                      <div
                        className="mb-5 flex items-center"
                        key={index}
                        style={{ minHeight: _maxTitleValue }}
                      >
                        <p className="text-lg leading-24 text-light-black">
                          {single?.title}
                        </p>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
          <div className="w-full lg:w-1/4 px-4 lg:px-2 lg:border-none border-2 border-black rounded-lg mb-8 lg:mb-0">
            <div ref={refHeading2} style={{ minHeight: maxValueHeading }}>
              <h1 className="text-4xl leading-48 text-light-black font-black pt-8 lg:pt-24">
                {section?.pricingPackages?.firstPackage.title}
              </h1>
              <p className="text-lg leading-24 text-light-black pt-5 pb-12 lg:min-h-9">
                {section?.pricingPackages?.firstPackage.description}
              </p>
              <Link to={section.pricingPackages.firstPackage.btnLink}>
                {" "}
                <button className="text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-4 py-3  lg:px-2 xl:px-7 lg:py-4 rounded-full cursor-pointer">
                  {section.pricingPackages.firstPackage.btnText}
                </button>
              </Link>
            </div>
            <div ref={ref} style={{ minHeight: maxValue }}>
              {containsNumbers(section?.pricingPackages?.firstPackage.price) ? (
                <h1 className="text-4xl leading-48 font-black pt-10">
                  ${section?.pricingPackages?.firstPackage.price}
                </h1>
              ) : (
                <h1 className="text-4xl leading-48 font-black pt-10">
                  {section?.pricingPackages?.firstPackage.price}
                </h1>
              )}
              <p className="text-dark-beige font-normal text-lg pt-1">
                /{section?.pricingPackages?.firstPackage.priceFor}
              </p>
            </div>
            <div
              className="mb-7 opacity-0 hidden lg:block"
              style={{ minHeight: _maxTitleValue }}
            >
              {" "}
              <h1 className="text-lg leading-24 font-bold text-black-two pt-8">
                {" "}
                WHAT’S INCLUDED{" "}
              </h1>{" "}
            </div>
            {section?.pricingPackages?.allPackageItems &&
              section?.pricingPackages?.allPackageItems.map((single, index) => {
                return (
                  <div
                    className={`lg:mb-5 ${
                      single?.first_package ? "py-4 lg:py-0" : ""
                    } flex items-center`}
                    key={index}
                    style={{ minHeight: _maxTitleValue }}
                  >
                    <div className="flex items-center">
                      <svg
                        className={
                          single?.first_package ? "opacity-1" : "opacity-0"
                        }
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.2792 0.945919C14.0338 0.700319 13.6312 0.700319 13.3856 0.945919L5.45971 8.87232C5.21411 9.11792 4.81171 9.11792 4.56611 8.87232L2.61395 6.92032C2.36835 6.67472 1.96595 6.67472 1.72035 6.92032L0.523067 8.11824C0.277467 8.36384 0.277467 8.76624 0.523067 9.01184L4.56611 13.0541C4.81171 13.2997 5.21411 13.2997 5.45971 13.0541L15.4765 3.0368C15.7223 2.79104 15.7223 2.3888 15.4765 2.14304L14.2792 0.945919Z"
                          fill="black"
                        ></path>
                      </svg>
                      <p className="text-lg leading-24 text-light-black ml-4 block lg:hidden">
                        {single?.first_package ? single?.title : ""}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="pr-4 px-5 pt-7 border-2 border-black rounded-lg mx-0 lg:mx-5 w-full lg:w-1/4 mb-8 lg:mb-0">
            <p className="text-left bg-extra-dark-gray text-light-black w-max italic text-base px-2 py-1 text-lg mb-8">
              {section.pricingPackages.secondPackage.offPercentage}
            </p>
            <div ref={refHeading3} style={{ minHeight: "max-content" }}>
              <h1 className="text-4xl leading-48 text-light-black font-black">
                {section?.pricingPackages?.secondPackage.title}
              </h1>
              <p className="text-lg leading-24 text-light-black pt-5 pb-12 lg:min-h-9">
                {section?.pricingPackages?.secondPackage.description}
              </p>
              <Link to={section.pricingPackages.secondPackage.btnLink}>
                {" "}
                <button className="text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-4 py-3  lg:px-2 xl:px-7 lg:py-4 rounded-full cursor-pointer">
                  {section.pricingPackages.secondPackage.btnText}
                </button>
              </Link>
            </div>
            <div ref={ref2} style={{ minHeight: maxValue }}>
              {containsNumbers(
                section?.pricingPackages?.secondPackage.price
              ) ? (
                <h1 className="text-4xl leading-48 font-black pt-10">
                  ${section?.pricingPackages?.secondPackage.price}
                </h1>
              ) : (
                <h1 className="text-4xl leading-48 font-black pt-10">
                  {section?.pricingPackages?.secondPackage.price}
                </h1>
              )}
              <p className="text-dark-beige font-normal text-lg pt-1">
                /{section?.pricingPackages?.secondPackage.priceFor}
              </p>
            </div>
            <div
              className="mb-7 opacity-0 hidden lg:block"
              style={{ minHeight: _maxTitleValue }}
            >
              <h1 className="text-lg leading-24 font-bold text-black-two pt-8">
                WHAT’S INCLUDED
              </h1>
            </div>
            {section?.pricingPackages?.allPackageItems &&
              section?.pricingPackages?.allPackageItems.map((single, index) => {
                return (
                  <div
                    className={`lg:mb-5 ${
                      single?.second_package ? "py-4 lg:py-0" : ""
                    } flex items-center`}
                    key={index}
                    style={{ minHeight: _maxTitleValue }}
                  >
                    <div className="flex items-center">
                      <svg
                        className={
                          single?.second_package ? "opacity-1" : "opacity-0"
                        }
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.2792 0.945919C14.0338 0.700319 13.6312 0.700319 13.3856 0.945919L5.45971 8.87232C5.21411 9.11792 4.81171 9.11792 4.56611 8.87232L2.61395 6.92032C2.36835 6.67472 1.96595 6.67472 1.72035 6.92032L0.523067 8.11824C0.277467 8.36384 0.277467 8.76624 0.523067 9.01184L4.56611 13.0541C4.81171 13.2997 5.21411 13.2997 5.45971 13.0541L15.4765 3.0368C15.7223 2.79104 15.7223 2.3888 15.4765 2.14304L14.2792 0.945919Z"
                          fill="black"
                        ></path>
                      </svg>
                      <p className="text-lg leading-24 text-light-black ml-4 block lg:hidden">
                        {single?.second_package ? single?.title : ""}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="w-full lg:w-1/4 px-3 lg:border-none border-2 border-black rounded-lg mb-8 lg:mb-0">
            <div ref={refHeading4} style={{ minHeight: maxValueHeading }}>
              <h1 className="text-4xl leading-48 text-light-black font-black pt-8 lg:pt-24">
                {section?.pricingPackages?.thirdPackage?.title}
              </h1>
              <p className="text-lg leading-24 text-light-black pt-5 pb-12 lg:min-h-9">
                {section?.pricingPackages?.thirdPackage?.description}
              </p>
              <Link to={section.pricingPackages.thirdPackage.btnLink}>
                {" "}
                <button className="text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-4 py-3  lg:px-2 xl:px-7 lg:py-4 rounded-full cursor-pointer">
                  {section.pricingPackages.thirdPackage.btnText}
                </button>
              </Link>
            </div>
            <div ref={ref3} style={{ minHeight: maxValue }}>
              <h1 className="text-4xl leading-48 font-black pt-10">
                {section?.pricingPackages?.thirdPackage.title}
              </h1>
            </div>
            <div
              className="mb-7 opacity-0 hidden lg:block"
              style={{ minHeight: _maxTitleValue }}
            >
              {" "}
              <h1 className="text-lg leading-24 font-bold text-black-two pt-8">
                {" "}
                WHAT’S INCLUDED{" "}
              </h1>{" "}
            </div>
            {section?.pricingPackages?.allPackageItems &&
              section?.pricingPackages?.allPackageItems.map((single, index) => {
                return (
                  <div
                    className={`lg:mb-5 ${
                      single?.third_package ? "py-4 lg:py-0" : ""
                    } flex items-center`}
                    key={index}
                    style={{ minHeight: _maxTitleValue }}
                  >
                    <div className="flex items-center">
                      <svg
                        className={
                          single?.third_package ? "opacity-1" : "opacity-0"
                        }
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.2792 0.945919C14.0338 0.700319 13.6312 0.700319 13.3856 0.945919L5.45971 8.87232C5.21411 9.11792 4.81171 9.11792 4.56611 8.87232L2.61395 6.92032C2.36835 6.67472 1.96595 6.67472 1.72035 6.92032L0.523067 8.11824C0.277467 8.36384 0.277467 8.76624 0.523067 9.01184L4.56611 13.0541C4.81171 13.2997 5.21411 13.2997 5.45971 13.0541L15.4765 3.0368C15.7223 2.79104 15.7223 2.3888 15.4765 2.14304L14.2792 0.945919Z"
                          fill="black"
                        ></path>
                      </svg>
                      <p className="text-lg leading-24 text-light-black ml-4 block lg:hidden">
                        {single?.third_package ? single?.title : ""}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default WorkbookPricing;
