import React from "react";
import "./style.css";
import BlockContent from "@sanity/block-content-to-react";
const AssesmentTool = ({ section: {content }, serializeContentEditor }) => {
  return (
    <>
      <div className="bg-light-black pt-32 pb-28 px-6 lg:px-0 flex justify-center items-start relative assementTool" data-theme="dark">
        <div className="lg:max-w-6xl mx-auto px-0 lg:px-16 xl:px-0 relative z-20">
          <h1 className="lg:text-146 font-Dazzed text-5xl leading-54 lg:leading-110 text-primary-white font-bold lg:font-black">
            Assessment <br /> <span className=" pl-12 lg:pl-48 font-Dazzed text-primary-white"> Tool</span>
          </h1>
          <div className="flex justify-center lg:pr-60 pr-0">
            <div className="w-full lg:w-2/3 lg:pl-20 pl-0">
              <BlockContent blocks={content?.content} serializers={serializeContentEditor()} />
            </div>
          </div>
          <div />
        </div>
        {/* Circles */}
        <div className="absolute top-0 lg:top-32 right-0 left-0 z-10 pt-32">
          <svg className="w-full" viewBox="0 0 1419 447" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle fill="rgba(144,114,54,.06)" cx="220.5" cy="118.5" r="47.5"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="44" cy="168" r="44"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="78" cy="418" r="25"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="339" cy="228" r="29"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="368" cy="118" r="29"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="1255" cy="348" r="29"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="1304.5" cy="48.5" r="20.5"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="1337" cy="168" r="82"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="1073.5" cy="248.5" r="81.5"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="779" cy="228" r="17"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="480.5" cy="408.5" r="38.5"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="561" cy="128" r="55"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="695.5" cy="188.5" r="39.5"></circle>
            <circle fill="rgba(144,114,54,.06)" cx="856" cy="68" r="68"></circle>
          </svg>
        </div>
      </div>
    </>
  );
};
export default AssesmentTool;
