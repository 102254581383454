import React from "react";
import Form from "../../forms/onlyText";
const BuyTheBookForm = () => {
  return (
    <>
      <div className="container mx-auto">
        <h1 className="font-bold text-lg pb-4">
          Get the first chapter for free
        </h1>
        <div className="pr-0 lg:pr-16 ">
        <Form name="trial" text="Download" event="joined_download_first_chapter"/>
        </div>
      </div>
    </>
  );
};
export default BuyTheBookForm;
