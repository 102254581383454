import React, { useState } from "react";
import ReactPlayer from "react-player/youtube";
import PlayButton from "../../../assets/images/playButton.png";
const TrustExerciseVideoSection = ({ section }) => {
  const [playing, setPlaying] = useState(false);
  const [buttonText, setButtonText] = useState("Watch");
  return (
    <>
      <div className="bg-primary-gray py-16">
        <div className="max-w-6xl mx-auto lg:px-16 flex flex-col-reverse lg:flex-row items-center justify-between">
          <div className="w-full lg:w-1/3 px-5 lg:px-0 pt-12 lg:pt-0">
            <h1 className="text-xl font-bold">{section?.title}</h1>
            <p className="text-lg pt-2 pb-8">{section?.description}</p>
            <button
              id="play-pause"
              className="bg-black text-white rounded-md px-4 py-2 flex items-center"
              onClick={() => {
                setPlaying(!playing);
                setButtonText(playing ? "Watch" : "Pause");
              }}
            >
              {buttonText}
              <img
                className="ml-3"
                src={PlayButton}
                alt="play-button"
                loading="lazy"
              />
            </button>
          </div>
          <div className="w-full lg:w-63 h-52 md:h-300 lg:h-363 mx-auto px-5 lg:px-0 inline-block">
            <ReactPlayer
              className="youtube-player"
              playing={playing}
              style={{ width: "100%" }}
              url={section?.url || ""}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default TrustExerciseVideoSection;
