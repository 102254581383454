import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import BlockContent from "@sanity/block-content-to-react";
import "./style.css";

const FAQ = ({ section }) => {
  const [active, setActive] = useState();

  const serializeContentEditor = () => {
    const serializers = {
      types: {
        block: (props) => {
          switch (props.node.style) {
            case "headline":
              return (
                <h1 className="pb-2 font-bold text-xl lg:leading-42">
                  {props.children}
                </h1>
              );
            case "h2":
              return (
                <h1 className="pb-2 font-bold text-xl lg:leading-42">
                  {props.children}
                </h1>
              );
            case "h3":
              return (
                <h1 className="pb-2 font-bold text-xl lg:leading-42">
                  {props.children}
                </h1>
              );

            case "smaller":
              return <p className="text-lg">{props.children}</p>;
            default:
              return <p className="text-lg leading-32">{props.children}</p>;
          }
        },
      },
      marks: {
        highlight: (props) => {
          return (
            <span className="text-xl lg:leading-42 font-bold font-Dazzed">
              {props.children}
            </span>
          );
        },
      },
      list: (props) => {
        return <ul className="text-lg ml-6 list-disc">{props?.children}</ul>;
      },
      listItem: (props) => {
        return <li className="mt-4">{props.children}</li>;
      },
    };
    return serializers;
  };

  return (
    <>
      {" "}
      <div className="container mx-auto px-5 lg:px-16 pt-6 lg:pt-0">
        <h1 className="font-Brother text-light-brown text-lg font-semibold uppercase">
          {section?.subHeading || ""}
        </h1>
        <h1 className="font-Dazzed text-4xl font-semibold pt-4 tracking-tight">
          {section?.mainHeading || ""}
        </h1>
        <div className="bg-extra-dark-gray h-0.5 w-full my-12 opacity-40" />

        <Accordion
          className="bg-inherit"
          allowZeroExpanded
          onChange={(e) => {
            setActive(e[0]);
          }}
        >
          {section?.faqItems &&
            section?.faqItems?.map((single, index) => {
              return (
                <AccordionItem
                  key={index}
                  uuid={index}
                  className={` rounded-lg py-5 my-6 px-4
                    ${active === index ? "bg-gray-two" : "bg-medium-beige"} `}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h1
                        className={`text-lg font-semibold rounded-lg font-Brother text-black-three pl-12 ${
                          active === index ? "bg-gray-two" : "bg-medium-beige"
                        } `}
                      >
                        {" "}
                        {single?.heading}
                      </h1>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <BlockContent
                      className="pl-16 font-Brother "
                      blocks={single?.content}
                      serializers={serializeContentEditor()}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
        <div className="flex items-center justify-center py-12">
          {section?.buttonLink && section?.buttonText && (
            <a
              className="text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-12 py-3 lg:px-2 xl:px-7 lg:py-3 rounded-full cursor-pointer"
              href={section?.buttonLink}
              rel="noopener"
              target={
                section?.buttonLink?.indexOf("http:") !== -1 ||
                section?.buttonLink?.indexOf("https:") !== -1
                  ? "_blank"
                  : "_self"
              }
            >
              {section?.buttonText}
            </a>
          )}
        </div>
      </div>
    </>
  );
};
export default FAQ;
