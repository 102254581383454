import React from "react";
import RedButton from "../redButton";
import { generateImageUrl, getImageSize } from "../../../sanity";
import { Link } from "gatsby";
const HomePageBookSection = ({
  section: { title, content, buttonText, buttonLink, image, newTab },
}) => {
  let pic = image ? image : "";
  const { width, height } = getImageSize(pic?.image?.asset?._ref);
  return (
    <>
      <div className="container mx-auto px-5 lg:px-16">
        <h1 className=" text-5xl leading-54 lg:leading-127 lg:text-116 font-Dazzed font-black ">
          {title}
        </h1>
        <div className="flex flex-col lg:flex-row justify-center items-center pt-12">
          <div className="w-full lg:w-1/2">
            <img
              height={height}
              width={width}
              src={generateImageUrl(pic?.image?.asset?._ref).url()}
              loading="lazy"
              alt={pic?.alt}
            />
          </div>
          <div className="w-full lg:w-1/2">
            <p className="text-2xl leading-28">{content}</p>
            <div className="mt-14 text-center lg:text-left">
              {newTab ? (
                <a href={buttonLink} target="_blank" rel="noopener noreferrer">
                  <RedButton>{buttonText}</RedButton>
                </a>
              ) : (
                <Link to={buttonLink}>
                  <RedButton>{buttonText}</RedButton>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePageBookSection;
