import React from "react";
import { generateImageUrl, getImageSize } from "../../../sanity";

const ServicesLogos = ({ section }) => {
  let _logos = section?.logos;

  let _firstRow = [];
  let _secondRow = [];

  for (let i = 0; i < 4; i++) {
    _firstRow.push(_logos[i]);
  }
  if (_logos.length > 4) {
    for (let u = 0; u < _logos.length; u++) {
      if (u > 3) {
        _secondRow.push(_logos[u]);
      }
    }
  }

  return (
    <div className="bg-medium-beige pb-14 pt-20">
      <div className="container mx-auto">
        <h1 className="font-semibold text-xl text-center pb-10">{section.heading}</h1>
        <div className="hidden lg:block">
          <ul className="container mx-auto flex flex-wrap items-center h-20 px-0 w-10/12 justify-center lg:px-17 lg:justify-between">
            {_firstRow?.map((logo, index) => {
              let url = generateImageUrl(logo?.logosImage?.asset?._ref).url();
              let alt = logo?.alt;
              const multiplier = 0.3;
              const { height, width } = getImageSize(
                logo?.logosImage?.asset?._ref
              );
              return (
                <li
                  key={index}
                  className="text-center w-30 lg:w-auto py-7 lg:py-5 px-2.5 lg:px-0 lg:y-0"
                >
                  <figure>
                    <img
                      src={url}
                      alt={alt}
                      loading="lazy"
                      width={width * multiplier}
                      height={height * multiplier}
                    />
                  </figure>
                </li>
              );
            })}
          </ul>
        </div>
        {_secondRow?.length && (
          <div className="hidden lg:block">
            <ul className="container mx-auto flex flex-wrap items-end justify-center px-0 mt-12 lg:px-6 lg:justify-between">
              {_secondRow?.map((logo, index) => {
                let url = generateImageUrl(logo?.logosImage?.asset?._ref).url();
                let alt = logo?.alt;
                const multiplier = 0.3;
                const { height, width } = getImageSize(
                  logo?.logosImage?.asset?._ref
                );
                return (
                  <li
                    key={index}
                    className="text-center w-30 lg:w-auto py-7 lg:py-5 px-2.5 lg:px-0 lg:y-0 h-20 flex items-end"
                  >
                    <figure>
                      <img
                        src={url}
                        alt={alt}
                        loading="lazy"
                        width={width * multiplier}
                        height={height * multiplier}
                      />
                    </figure>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {/* for mobile  */}
        <ul className="container mx-auto flex flex-wrap items-center justify-center px-0 w-full block lg:justify-between lg:hidden">
          {_logos?.map((logo, index) => {
            let url = generateImageUrl(logo?.logosImage?.asset?._ref).url();
            let alt = logo?.alt;
            const multiplier = 0.3;
            const { height, width } = getImageSize(
              logo?.logosImage?.asset?._ref
            );
            return (
              <li
                key={index}
                className="text-center w-30 lg:w-auto py-7 lg:py-5 px-2.5 lg:px-0 lg:y-0"
              >
                <figure>
                  <img
                    src={url}
                    alt={alt}
                    loading="lazy"
                    width={width * multiplier}
                    height={height * multiplier}
                  />
                </figure>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default ServicesLogos;
