import React from "react";
import { generateImageUrl, getImageSize } from "../../../sanity";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
const HomeServices = ({ section: { services }, serialize }) => {
  return (
    <>
      {services &&
        services.map((service, index) => {
          if (index % 2 === 0) {
            const { width, height } = getImageSize(service?.image?.asset?._ref);
            return (
              <div
                className="flex flex-col lg:flex-row w-full lg:max-w-5xl px-0 lg:px-16 xl:px-0 mx-auto items-center pb-8"
                key={index}
              >
                <div className="w-full lg:w-1/2 pr-4 pl-4 md:pr-11 md:pl-11 lg:px-0 lg:pr-24 justify-center lg:justify-start">
                  <img
                    height={height}
                    width={width}
                    src={generateImageUrl(service?.image?.asset?._ref).url()}
                    loading="lazy"
                    alt="workbook"
                    className="w-full rounded"
                  />
                </div>
                <div className="w-full lg:w-1/2 px-5 md:px-11 lg:px-0">
                  <h1 className="text-3xl lg:text-largexl leading-32 lg:leading-54 font-bold pt-8 lg:pt-0 pb-8">
                    {service?.title}
                  </h1>
                  <BlockContent
                    blocks={service?.description}
                    serializers={serialize("text-2xl leading-32 pb-16 lg:pb-8")}
                  />
                  {service?.linkURL && (
                    <Link to={service?.linkURL}>
                      <button
                        className={`cursor-pointer underline hover:opacity-50 text-primary-red hover:no-underline`}
                      >
                        {service?.linkText}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            );
          } else {
            const { width, height } = getImageSize(service?.image?.asset?._ref);
            return (
              <div className="py-24 my-8" key={index}>
                <div className="flex flex-col-reverse lg:flex-row w-full lg:max-w-5xl px-0 lg:px-16 xl:px-0 mx-auto items-center">
                  <div className="w-full lg:w-1/2 px-5 md:px-11 lg:px-0">
                    <h1 className="text-3xl lg:text-largexl leading-32 lg:leading-54 font-bold pt-8 lg:pt-0 pb-8">
                      {service?.title}
                    </h1>
                    <BlockContent
                      blocks={service?.description}
                      serializers={serialize(
                        "text-2xl leading-32 pb-16 lg:pb-8"
                      )}
                    />
                    {service?.linkURL && (
                      <Link to={service?.linkURL}>
                        <button
                          className={`cursor-pointer underline hover:opacity-50 text-primary-red hover:no-underline`}
                        >
                          {service?.linkText}
                        </button>
                      </Link>
                    )}
                  </div>
                  <div className="w-full lg:w-1/2 flex justify-center lg:justify-end pr-4 pl-4 md:pr-11 md:pl-11 lg:px-0 lg:pl-24">
                    <img
                      height={height}
                      width={width}
                      src={generateImageUrl(service?.image?.asset?._ref).url()}
                      loading="lazy"
                      alt="workbook"
                      className="w-full rounded"
                    />
                  </div>
                </div>
              </div>
            );
          }
        })}
    </>
  );
};

export default HomeServices;
