import React from "react";
import SingleDecorator from "../../../../assets/images/singleDecorator.png";
const ThreeCircles = () => {
  return (
    <>
      <img src={SingleDecorator} alt="circles" loading="lazy" />
    </>
  );
};

export default ThreeCircles;
