import React from "react";
import { generateImageUrl } from "../../../sanity";
const BrandAssetsPhotography = ({ content }) => {
  return (
    <>
      <div className="container mx-auto lg:px-17">
        <div className="border-b-2 border-light-yellow border-opacity-30">
          <h1 className="text-2.7 leadgin-54 font-bold text-center pt-24 pb-8 px-4 lg:px-0">
            {content?.title}
          </h1>
          <div className="w-full lg:w-9/12 mx-auto flex flex-wrap justify-between px-4 lg:px-0">
            {content?.images?.map((each, index) => (
              <div className="w-47 mt-8" key={index}>
                <div
                  className="brand-assets-image"
                  style={{
                    backgroundImage: `url(${generateImageUrl(
                      each?.image?.asset?._ref
                    ).url() || ""})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                  }}
                ></div>
              </div>
            ))}
          </div>
          <div className="flex justify-center py-12">
            <a
              className="text-primary-red underline hover:text-black hover:no-underline cursor-pointer w-max"
              target="_blank"
              rel="noopener noreferrer"
              href={`${content?.linkUrl}`}
            >
              {content?.linkText}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default BrandAssetsPhotography;
