import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import Spacer from "../../spacer";
const TrustExerciseElements = ({ section, serializeContentEditor }) => {
  return (
    <>
      <div className="bg-black">
        <Spacer section={{ size: "m" }} />
        <div className="max-w-6xl mx-auto lg:px-36 px-5 text-primary-gray">
          <div className="w-full lg:px-0 px-5 mx-auto">
            <div className="w-full lg:w-72 mx-auto">
              <h1 className="text-2.8 font-bold text-left">{section?.title}</h1>
              <div className="pt-6 w-72 mediumBlockContentTrustFall">
                <BlockContent
                  blocks={section?.content?.content}
                  serializers={serializeContentEditor()}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-between w-full lg:w-72 pt-12 mx-auto text-center text-primary-gray">
              {section?.trustElements?.map((single, index) => {
                return (
                  <div className="pt-12 w-45" key={index}>
                    <h1 className="lg:text-2xl text-xl font-semibold">
                      {single.elementTitle}
                    </h1>
                    <div className="pt-3 smallBlockContent">
                      <BlockContent
                        blocks={single?.elementContent?.content}
                        serializers={serializeContentEditor()}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Spacer section={{ size: "s" }} />
      </div>
    </>
  );
};
export default TrustExerciseElements;
