import React from "react";
import { generateImageUrl } from "../../../sanity";
import { Link } from "gatsby";
import Spacer from "../../spacer";
const ServicesOffer = ({ section }) => {
  const LinkReturnHandler = ({ link, data }) => {
    return (
      <a
        className={`${link ? "pointer-events-auto" : "pointer-events-none"}`}
        target={
          link?.indexOf("http:") !== -1 || link?.indexOf("https:") !== -1
            ? "_blank"
            : "_self"
        }
        rel="noopener noreferrer"
        href={link}
      >
        {data}
      </a>
    );
  };
  return (
    <div>
      <Spacer section={{ size: "s" }} />
      <div className="container mx-auto">
        <h1 className="text-4xl font-semibold text-center font-Dazzed">
          {section.heading}
        </h1>
        <div className="flex flex-wrap justify-center p-0">
          {section?.offers.map((single, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-full py-6 mt-10 px-2 lg:w-31 lg:px-6 h-full"
            >
              <div className="flex items-center justify-center mb-4 lg:mb-6 h-6 w-8 lg:h-8 lg:w-10">
                <LinkReturnHandler
                  link={single?.link}
                  data={
                    <img
                      loading="lazy"
                      src={generateImageUrl(
                        single?.offersImage?.asset?._ref
                      ).url()}
                      alt={single.offersImagealt}
                    />
                  }
                />
              </div>
              <div className="min-h-50">
                <LinkReturnHandler
                  link={single?.link}
                  data={
                    <h1 className="font-semibold text-xl text-center">
                      {single.heading}
                    </h1>
                  }
                />
              </div>

              <LinkReturnHandler
                link={single?.link}
                data={
                  <p className="text-xl opacity-40 leading-28 text-center">
                    {single.description}
                  </p>
                }
              />
            </div>
          ))}
        </div>
        {section.button && (
          <div className="flex justify-center mt-4">
            <Link to={section.ButtonLink}>
              <button
                className="text-base bg-dark-beige hover:text-white ease-in-out transition duration-300 px-6 py-3 rounded-full"
                type="button"
              >
                {section.button}
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default ServicesOffer;
