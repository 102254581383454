import React from "react";
import { generateImageUrl } from "../../../sanity";
import { Link } from "gatsby";

const CourseTraining = ({ section }) => {
  return (
    <div className="container mx-auto px-6 lg:px-0">
      <div className="text-center w-full mx-auto lg:w-70">
        <h1 className="leading-60 font-Dazzed font-bold tracking-wide text-black text-4.4 lg:text-5.9">
          {section.mainHeading}
        </h1>
        <p className="leading-34 font-Brother font-medium text-lg pt-6 mb-16 px-6 lg:text-2xl ">
          {section.subHeading}{" "}
        </p>
      </div>
      {section?.trainingCard.map((single, index) => (
        <div
          key={index}
          className="training-card-border px-4 lg:px-10 py-10 rounded-md mt-6 w-full mx-auto lg:w-86"
        >
          <div className="flex items-center w-full">
            <img
              className="w-4"
              src={generateImageUrl(single?.cardImage?.asset?._ref).url()}
              alt={single.cardImageAlt}
              loading="lazy"
            />
            <p className="text-light-yellow font-Dazzed text-13 ml-2">
              {single.calendarText}
            </p>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center">
            <div className="lg:w-76 w-full">
              <h1 className="font-semibold tracking-normal text-black pt-2 pb-3 text-3xl leading-40 lg:tracking-wide lg:text-4.9 lg:leading-60">
                {single.heading}
              </h1>
              <p className="font-medium text-lg lg:text-2xl">
                {single.subheading}
              </p>
            </div>

            <Link to={single.cardButtonlink}>
              <div className="mt-4 w-full lg:mt-0">
                <button
                  className="w-full ease-in-out transition duration-300 hover:text-white rounded-full bg-light-yellow px-10 py-4 text-lg"
                  type="button"
                >
                  {single.cardButton}
                </button>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CourseTraining;
