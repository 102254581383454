import React from "react";
const meetingForm = ({ section }) => {
  return (
    <>
      {section?.meetingForm && (
        <div className="container mx-auto">
          <div
            class="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/kristen-diviney?embed=true"
          ></div>
        </div>
      )}
    </>
  );
};
export default meetingForm;
