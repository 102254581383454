import React from "react";
const FullMediaKit = ({ section }) => {
  return (
    <>
      <div className="container mx-auto pt-20 lg:pt-24 lg:px-17 flex justify-center flex-col items-center">
        <a
          className="text-primary-red underline hover:text-black hover:no-underline cursor-pointer mb-8 w-max"
          target="_blank"
          rel="noopener noreferrer"
          href={`${section?.downloadALlUrl}`}
        >
          {section?.downloadALlText || ""}
        </a>
        <a
          className="text-dark-beige cursor-pointer w-max"
          href={`mailto:${section?.contactEmail}`}
        >
          Contact us: {section?.contactEmail}
        </a>
      </div>
    </>
  );
};
export default FullMediaKit;
