import React from "react";
import { generateImageUrl } from "../../../sanity";
import { Link } from "gatsby";

const ServicesHero = ({ section, header_options }) => {
  let Url =
    section.mainBg.asset._ref &&
    generateImageUrl(section.mainBg.asset._ref).url();
  return (
      <div
        className={`flex items-end px-4 relative
        ${
          header_options?.bg_transparent ? "-top-40" : "top-0"
        } h-full lg:items-center`}
        style={{
          backgroundImage: `url(${Url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "836px",
        }}
      >

        <div
          className={`container lg:px-17 mx-auto ${
            section?.text_black ? "text-black" : "text-white"
          }`}
        >
          <div className="w-full lg:w-4/5">
            <div className="lg:mt-60">
              <h1 className="font-bold text-4.5 leading:60 font-Dazzed tracking-wide lg:text-7xl lg:leading-80">
                {section.heading}
              </h1>
              <p className="font-medium text-lg mt-4 mb-12 leading-28 lg:text-2xl">
                {section.description}
              </p>
              <div className="flex flex-col lg:flex-row items-start lg:items-center mb-14 lg:mb-12">
                <Link
                  to={section.leftButtonLink}
                  className={`w-fit ease-in-out transition duration-300 text-base text-center ${
                    !section?.text_black
                      ? "hover:text-white bg-white text-black hover:bg-transparent"
                      : "hover:text-black hover:bg-transparent text-white bg-black border-black"
                  }  rounded border border-2 py-3 px-6`}
                >
                  {section.leftButton}
                </Link>
                <Link
                  to={section.RightButtonLink}
                  className={`w-fit ease-in-out transition duration-300 text-base ml-0 lg:ml-4 text-center ${
                    !section?.text_black
                      ? "bg-transparent text-white hover:text-black hover:bg-white"
                      : "text-black bg-transparent text-black hover:text-white hover:bg-black border-black"
                  }   rounded border border-2 py-3 px-6 mt-5 lg:mt-0`}
                >
                  {section.RightButton}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default ServicesHero;
