import React, { useState } from "react";
import { generateImageUrl } from "../../../sanity";
import Decorator from "../../../assets/images/servicesDecorator.png";
import Spacer from "../../spacer";
import BlockContent from "@sanity/block-content-to-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const CourseCollectiveAndIndividual = ({ section }) => {
  const [active, setActive] = useState();
  const serializeContentEditor = (classes, isCustom, isExerpt) => {
    const serializers = {
      types: {
        block: (props) => {
          switch (props.node.style) {
            case "normal":
              return (
                <p
                  className={`${
                    !isCustom ? "text-2xl leading-32" : ""
                  } ${classes}`}
                >
                  {isExerpt
                    ? props?.children[0].substring(0, 150).concat("...")
                    : props.children}
                </p>
              );

            case "headline":
              return (
                <h3 className="headline pb-16 font-bold text-3xl lg:text-4xl lg:leading-42">
                  {props.children}
                </h3>
              );
            case "smaller":
              return <p className="text-xl">{props.children}</p>;
            default:
              return (
                <p className="text-lg: lg:text-2xl leading-32">
                  {props.children}
                </p>
              );
          }
        },
      },
      marks: {
        highlight: (props) => {
          return (
            <span className="highlight text-3xl lg:text-4xl lg:leading-42 text-white lg:text-dark-beige font-bold font-Dazzed">
              {props.children}
            </span>
          );
        },
      },
      list: (props) => {
        return <ul>{props?.children}</ul>;
      },
      listItem: (props) => {
        return (
          <li className="content-list relative text-lg lg:text-2xl mb-4">
            {" "}
            <b>
              {" "}
              {props.node.listItem === "number" && props.index <= 9
                ? "0" + (props.index + 1)
                : props.index + 1}{" "}
            </b>
            {props.children}
          </li>
        );
      },
    };
    return serializers;
  };

  return (
    <div>
      <Spacer section={{ size: "s" }} />
      <div className="container mx-auto relative">
        <div className="w-full lg:w-91 mx-auto px-6 lg:px-0 ">
          <div className="flex lg:flex-row flex-col justify-end w-full">
            <div className="w-full lg:w-3/5 mr-0 lg:mr-8">
              <h1 className="leading-36 font-Dazzed font-bold tracking-wide text-black text-4xl lg:text-5xl lg:leading-60">
                {section.mainHeading}
              </h1>
              <p className="leading-34 font-Brother font-medium text-lg lg:text-2xl pt-5 pr-0 lg:pr-8">
                {section.subHeading}
              </p>
              {section?.accordion?.length ? (
                <Accordion
                  allowZeroExpanded={true}
                  className="mr-6 mt-24"
                  onChange={(e) => {
                    setActive(e[0]);
                  }}
                >
                  {section?.accordion?.map((accordion, accordionIndex) => {
                    return (
                      <AccordionItem uuid={accordionIndex} key={accordionIndex}>
                        <AccordionItemHeading>
                          <AccordionItemButton className="text-xl">
                            <p
                              className={`text-4xl font-Dazzed font-bold text-black leading-38 py-6 border-black lg:leading-50 ${
                                accordionIndex !== 0 && "border-t-2"
                              } ${
                                active === accordionIndex ? "" : "opacity-30"
                              }`}
                            >
                              {accordion.title}
                            </p>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <BlockContent
                            blocks={accordion.content}
                            serializers={serializeContentEditor()}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              ) : (
                <div />
              )}
            </div>
            <div className="w-full lg:w-40">
              <img
                className="rounded-md"
                src={generateImageUrl(section?.sideimage?.asset?._ref).url()}
                alt={section.sideimageAlt}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <img
          className="absolute -top-20 hidden lg:block"
          src={Decorator}
          alt="decorator"
          loading="lazy"
        />
      </div>
    </div>
  );
};
export default CourseCollectiveAndIndividual;
