import React from "react";
import { generateImageUrl } from "../../../sanity";
import Slider from "@ant-design/react-slick";
import Star from "../../../assets/images/star.png";
import Avatar from "../../../assets/images/avatar.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Testimonial = ({ section }) => {
  let backupData = section?.testimonials.slice();

  const middleIndex = Math.ceil(backupData.length / 2);
  const firstHalf =
    section?.testimonials?.length > 10
      ? backupData.splice(0, middleIndex)
      : backupData;

  const secondHalf =
    section?.testimonials?.length > 10 ? backupData.splice(-middleIndex) : [];

  const settings = {
    cssEase: "linear",
    autoplay: true,
    infinite: true,
    slidesToShow: firstHalf.length > 4 ? 4.3 : firstHalf.length,
    slidesToScroll: 1,
    autoplaySpeed: 0,
    speed: 8000,
    arrows: false,
    swipe: true,
    pauseOnFocus: true,
    pauseOnHover: true,
  };

  const settingsSecond = {
    cssEase: "linear",
    autoplay: true,
    infinite: true,
    slidesToShow: secondHalf.length > 4 ? 4.3 : secondHalf.length,
    slidesToScroll: 1,
    autoplaySpeed: 0,
    speed: 8000,
    arrows: false,
    swipe: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    rtl: true,
  };
  const settingsMobile = {
    cssEase: "linear",
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className={`w-full testimonial overflow-hidden py-12 ${
          section?.darkmode ? "text-white bg-light-black" : ""
        }`}
      >
        <div className="mb-2 hidden lg:block">
          <Slider {...settings}>
            {firstHalf?.map((testimonial, testimonialIndex) => {
              let url = testimonial?.image?.asset?._ref
                ? generateImageUrl(testimonial?.image?.asset?._ref).url()
                : null;
              return (
                <div
                  className="border border-extra-dark-gray border-2 rounded-md p-4 min-h-166 my-5"
                  key={testimonialIndex}
                >
                  <>
                    <div>
                      <div className="flex items-start">
                        <div>
                          {url ? (
                            <div
                              className="w-12 h-12 rounded-full"
                              style={{
                                backgroundImage: `url(${url})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                minHeight: "100%",
                                with: "100%",
                              }}
                            ></div>
                          ) : (
                            <img src={Avatar} alt="avatar" loading="lazy" />
                          )}
                        </div>
                        <div className="pl-3">
                          <h2 className="text-base font-semibold text-left">
                            {testimonial?.customerName}
                          </h2>
                          <div className="flex items-center">
                            <img
                              width={16}
                              src={Star}
                              alt="star"
                              loading="lazy"
                            />
                            <h1 className="pl-2">{testimonial?.rating}/5</h1>
                          </div>
                        </div>
                      </div>
                      <div className="pl-14">
                        <p className="pt-3 leading-6">
                          {testimonial?.description.length > 80
                            ? testimonial?.description
                                .substring(0, 80)
                                .concat("...")
                            : testimonial?.description}
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="testimonial-second hidden lg:block">
          <Slider {...settingsSecond}>
            {secondHalf?.map((testimonial, testimonialIndex) => {
              let url = testimonial?.image?.asset?._ref
                ? generateImageUrl(testimonial?.image?.asset?._ref).url()
                : null;
              return (
                <div
                  className="border border-2 border-extra-dark-gray rounded-md p-4 min-h-166 my-5"
                  key={testimonialIndex}
                >
                  <>
                    <div>
                      <div className="flex items-start">
                        <div>
                          {url ? (
                            <div
                              className="w-12 h-12 rounded-full"
                              style={{
                                backgroundImage: `url(${url})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                minHeight: "100%",
                                with: "100%",
                              }}
                            ></div>
                          ) : (
                            <img src={Avatar} alt="avatar" loading="lazy" />
                          )}
                        </div>
                        <div className="pl-3">
                          <h2 className="text-base font-semibold text-left">
                            {testimonial?.customerName}
                          </h2>
                          <div className="flex items-center">
                            <img
                              width={16}
                              src={Star}
                              alt="star"
                              loading="lazy"
                            />
                            <h1 className="pl-2">{testimonial?.rating}/5</h1>
                          </div>
                        </div>
                      </div>
                      <div className="pl-14">
                        <p className="pt-3 leading-6">
                          {testimonial?.description.length > 80
                            ? testimonial?.description
                                .substring(0, 80)
                                .concat("...")
                            : testimonial?.description}
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              );
            })}
          </Slider>
        </div>

        {/* Mobile Testimonials */}
        <div className="testimonial-second block lg:hidden">
          <Slider {...settingsMobile}>
            {section?.testimonials?.map((testimonial, testimonialIndex) => {
              let url = testimonial?.image?.asset?._ref
                ? generateImageUrl(testimonial?.image?.asset?._ref).url()
                : null;
              return (
                <div
                  className="border border-2 border-extra-dark-gray rounded-md p-4 min-h-166 my-5"
                  key={testimonialIndex}
                >
                  <>
                    <div>
                      <div className="flex items-start">
                        <div>
                          {url ? (
                            <div
                              className="w-12 h-12 rounded-full"
                              style={{
                                backgroundImage: `url(${url})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                minHeight: "100%",
                                with: "100%",
                              }}
                            ></div>
                          ) : (
                            <img src={Avatar} alt="avatar" loading="lazy" />
                          )}
                        </div>
                        <div className="pl-3">
                          <h2 className="text-base font-semibold text-left">
                            {testimonial?.customerName}
                          </h2>
                          <div className="flex items-center">
                            <img
                              width={16}
                              src={Star}
                              alt="star"
                              loading="lazy"
                            />
                            <h1 className="pl-2">{testimonial?.rating}/5</h1>
                          </div>
                        </div>
                      </div>
                      <div className="pl-14">
                        <p className="pt-3 leading-6">
                          {testimonial?.description}
                        </p>
                      </div>
                    </div>
                  </>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};
export default Testimonial;
