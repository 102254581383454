import React from "react";
const SocialLinks = ({ section }) => {
  return (
    <div className="container mx-auto px-4 lg:px-0">
      <h1 className="text-2xl lg:text-4xl font-brother font-bold text-center mb-28">{section?.title || ""}</h1>
      <div className="w-11/12 lg:w-2/5 mx-auto">
        {section.links && section.links.length ? (
          section?.links?.map((link, index) => {
            return (
              <a key={index} href={link?.link} target="_blank" rel="noopener noreferrer">
                <div className={`w-full border-2 border-black rounded cursor-pointer ease-in-out transition ${index !== section.links - 1 ? "mb-10" : ""}`}>
                  <p className="text-lg text-center py-5 w-full mb-0">{link?.title}</p>
                </div>
              </a>
            );
          })
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
export default SocialLinks;
