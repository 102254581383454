import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import Spacer from "../../spacer";
import DecoratorUnderstandingTrust from "../decorators/decoratorSeven";
const TrustExerciseUnderstanding = ({ section, serializeContentEditor }) => {
  return (
    <>
      <div className="bg-black">
        <Spacer section={{ size: "m" }} />
        <div className="max-w-6xl mx-auto lg:px-16 px-5 text-primary-gray relative">
          <div className="w-100 lg:w-9/12 mx-auto px-5 lg:px-0">
            <h1 className="text-4xl lg:text-8xl font-bold text-left lg:text-center">
              {section?.title}
            </h1>
            <div className="w-full px-0 lg:px-36 pt-8">
              <BlockContent
                blocks={section?.content?.content}
                serializers={serializeContentEditor()}
              />
            </div>
          </div>
          <div className="absolute top-40 hidden lg:block">
            <DecoratorUnderstandingTrust />
          </div>
        </div>
        <Spacer section={{ size: "m" }} />
      </div>
    </>
  );
};
export default TrustExerciseUnderstanding;
