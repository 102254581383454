import React from "react";
import { generateImageUrl } from "../../../sanity";
import { dateFormatHandler } from "../../../utils";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
const BlogCard = ({ card, serialize }) => {
  return (
    <>
      <div className="blog bg-extra-dark-gray w-full lg:w-30 mb-12 mt-12 lg:mb-0 rounded-lg shadow-sm">
        <Link
          to={`/blog/${card?.slug?.current}`}
          aria-label={card?.title || ""}
        >
          <div
            style={{
              backgroundImage: `url(${generateImageUrl(
                card?.hero_image?.asset?._ref
              ).url()})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              minHeight: "210px",
            }}
          ></div>
        </Link>
        <div className="px-3">
          <Link to={`/blog/${card?.slug?.current}`}>
            <h1 className="leading-32 text-2xl font-bold pt-8">
              {card?.title || ""}
            </h1>
          </Link>
          <BlockContent
            blocks={card?.excerpt?.content}
            serializers={serialize("leading-21 text-base pt-4", true, true)}
          />
          <div className="flex justify-between pt-8 pb-3">
            <div>
              <span className="leading-31 text-sm">
                {dateFormatHandler(card?.date)}
              </span>
            </div>
            <div>
              <span>
                <Link
                  to={`/blog/${card?.slug?.current}`}
                  className="text-primary-red cursor-pointer hover:text-black font-bold"
                >
                  Read More
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogCard;
