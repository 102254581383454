import React from "react";
import { generateImageUrl } from "../../../sanity";
const TrustExerciseSingleImage = ({ section }) => {
  let bgImage = generateImageUrl(section?.image?.image?.asset?._ref).url();
  return (
    <>
      <div
        className="w-full h-52 lg:h-72"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100%",
          with: "100%",
        }}
      ></div>
    </>
  );
};
export default TrustExerciseSingleImage;
