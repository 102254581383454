import React from "react";
import { generateImageUrl } from "../../../sanity";
const BlindSpotAndStrength = ({ section }) => {
  return (
    <>
      <div className="container mx-auto px-5 lg:px-16 pt-6 lg:pt-0 font-brother">
        <div className="w-full md:w-52 mx-auto">
          <h1 className="text-4xl font-semibold text-center leading-48">
            {section?.heading}
          </h1>
          <p className="text-xl leading-30 text-light-black text-center px-4 md:px-10 pt-6">
            {section?.subHeading}
          </p>
        </div>
        <img
          className="my-20 rounded-md"
          src={
            section.image.asset._ref &&
            generateImageUrl(section.image.asset._ref).url()
          }
          alt="table-image"
        />
        {/* <div className="bg-white rounded-md w-full mt-12 md:mt-24 mb-16">
          <div className="bg-gray-two mx-1 py-3 rounded-b-none rounded-md pl-4 relative top-0.5">
            <ul className="flex justify-between items-center text-black font-semibold text-sm">
              <li className="w-52">EMPATHY</li>
              <li className="w-5">Self</li>
              <li className="w-5">AVG</li>
              <li className="w-5">MGR</li>
              <li className="w-5">PR</li>
              <li className="w-5">DR</li>
              <li className="w-5">OT</li>
              <li className="w-10">Range</li>
            </ul>
          </div>
          <div className="bg-gray-two mx-1 py-3 rounded-t-none rounded-md pl-4 mt-0.5 relative top-0.5">
            <ul className="flex justify-between items-center text-black font-semibold text-sm">
              <li className="w-52 opacity-0">EMPATHY</li>
              <li className="w-5">3.1</li>
              <li className="w-5">3.6</li>
              <li className="w-5">3.8</li>
              <li className="w-5">3.8</li>
              <li className="w-5">3.4</li>
              <li className="w-5">3.5</li>
              <li className="w-10">
                <span className="pr-3">1</span>
                <span className="pr-3">2</span>
                <span className="pr-3">3</span>
                <span className="pr-3">4</span>
                <span>5</span>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <ul>
              {section?.listItems &&
                section?.listItems?.map((single, index) => {
                  let _range = 100 - single?.range * 20;
                  return (
                    <li
                      className={`flex justify-between items-center text-black text-sm border border-t-0 border-r-0 border-1 border-l-0 bg-white mx-1 py-3 rounded-b-none rounded-md pl-4 font-medium ${
                        index === section?.listItems?.length - 1
                          ? "border-b-0"
                          : ""
                      }`}
                      key={index}
                    >
                      <div className="w-52 text-sm">{single?.title}</div>
                      <div className="w-5 text-xs">
                        {" "}
                        <p
                          className={`rounded-full h-7 w-7 flex items-center justify-center ${
                            single?.self < 3
                              ? "bg-red-300"
                              : single?.self > 4
                              ? "bg-light-green"
                              : ""
                          }`}
                        >
                          {single?.self}
                        </p>
                      </div>
                      <div className="w-5 text-xs">
                        {" "}
                        <p
                          className={`rounded-full h-7 w-7 flex items-center justify-center ${
                            single?.avg < 3
                              ? "bg-red-300"
                              : single?.avg > 4
                              ? "bg-light-green"
                              : ""
                          }`}
                        >
                          {single?.avg}
                        </p>
                      </div>
                      <div className="w-5 text-xs">
                        {" "}
                        <p
                          className={`rounded-full h-7 w-7 flex items-center justify-center ${
                            single?.mgr < 3
                              ? "bg-red-300"
                              : single?.mgr > 4
                              ? "bg-light-green"
                              : ""
                          }`}
                        >
                          {single?.mgr}
                        </p>
                      </div>
                      <div className="w-5 text-xs">
                        {" "}
                        <p
                          className={`rounded-full h-7 w-7 flex items-center justify-center ${
                            single?.pr < 3
                              ? "bg-red-300"
                              : single?.pr > 4
                              ? "bg-light-green"
                              : ""
                          }`}
                        >
                          {single?.pr}
                        </p>
                      </div>
                      <div className="w-5 text-xs">
                        {" "}
                        <p
                          className={`rounded-full h-7 w-7 flex items-center justify-center ${
                            single?.dr < 3
                              ? "bg-red-300"
                              : single?.dr > 4
                              ? "bg-light-green"
                              : ""
                          }`}
                        >
                          {single?.dr}
                        </p>
                      </div>
                      <div className="w-5 text-xs">
                        {" "}
                        <p
                          className={`rounded-full h-7 w-7 flex items-center justify-center ${
                            single?.ot < 3
                              ? "bg-red-300"
                              : single?.ot > 4
                              ? "bg-light-green"
                              : ""
                          }`}
                        >
                          {single?.ot}
                        </p>
                      </div>
                      <div className="w-10 pl-1 pr-7 flex justify-end overflow-hidden">
                        <div
                          className={`bg-light-gray h-5`}
                          style={{ width: _range ? _range : 10 }}
                        ></div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default BlindSpotAndStrength;
