import React from "react";
import { generateImageUrl } from "../../../sanity";
import { Link } from "gatsby";
const TrustPageCard = ({ section: { services } }) => {
  return (
    <>
      <div className="attribute-blog w-full lg:max-w-6xl px-0 lg:px-16 xl:px-0 mx-auto md:px-11 lg:px-0">
        <div className="flex flex-col lg:flex-row justify-between pt-12 lg:pt-0">
          {services &&
            services.map((service, index) => {
              if (index % 2 === 0) {
                return (
                  <div
                    className="blog bg-extra-dark-gray px-5 w-full lg:w-30 mb-12 lg:mb-0 pb-8 rounded-lg shadow-sm relative"
                    key={index}
                  >
                    <div className="px-3">
                      <h1 className="leading-42 text-4xl font-bold pt-16 pb-3">
                        {service?.title}
                      </h1>
                      <div
                        style={{
                          backgroundImage: `url(${generateImageUrl(
                            service?.image?.asset?._ref
                          ).url()})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          width: "100%",
                          minHeight: "80px",
                        }}
                      ></div>
                      <div>
                        <p className="text-xl py-6 font-medium">
                          {service?.description}
                        </p>
                      </div>
                      <div className="pt-8">
                        <div className="absolute bottom-9">
                          <div>
                            <span>
                              {service?.newTab ? (
                                <a
                                  href={service?.linkURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <button className="cursor-pointer underline hover:text-black text-primary-red hover:no-underline">
                                    {service?.linkText}
                                  </button>
                                </a>
                              ) : (
                                <Link to={service?.linkURL}>
                                  <button className="cursor-pointer underline hover:text-black text-primary-red hover:no-underline">
                                    {service?.linkText}
                                  </button>
                                </Link>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="blog bg-extra-dark-gray px-5 w-full lg:w-30 mb-12 lg:mb-0 pb-8 rounded-lg shadow-sm relative"
                    key={index}
                  >
                    <div className="px-3">
                      <h1 className="leading-42 text-4xl font-bold pt-16 pb-3">
                        {service?.title}
                      </h1>
                      <div>
                        <p className="text-xl pt-0 pb-10">{service?.description}</p>
                      </div>
                      <div
                        style={{
                          backgroundImage: `url(${generateImageUrl(
                            service?.image?.asset?._ref
                          ).url()})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          width: "40%",
                          height: "150px",
                        }}
                      ></div>
                      <div className="pt-8">
                        <div className="absolute bottom-9">
                          <div>
                            <span>
                              {service?.newTab ? (
                                <a href={service?.linkURL} target="_blank">
                                  <button className="cursor-pointer underline hover:text-black text-primary-red hover:no-underline">
                                    {service?.linkText}
                                  </button>
                                </a>
                              ) : (
                                <Link to={service?.linkURL}>
                                  <button className="cursor-pointer underline hover:text-black text-primary-red hover:no-underline">
                                    {service?.linkText}
                                  </button>
                                </Link>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

export default TrustPageCard;
