import React from "react";
import { generateImageUrl, getImageSize } from "../../../sanity";
import Spacer from "../../spacer";
const Logos = ({ section }) => {
  return (
    <>
      <div className="w-full lg:max-w-7xl mx-auto px-5 lg:px-0 flex flex-col lg:flex-row">
        <div className="w-full lg:w-58 pl-0 lg:pl-72">
          <div className="pt-12 lg:pt-0">
            <p className="text-2xl">-</p>
            <p className="font-bold text-2xl lg:text-lg md:px-6 lg:px-0">
              {section?.mobileLeadText}
            </p>
          </div>
        </div>
      </div>
      <Spacer section={{ size: "s" }} />
      <p className="text-center font-bold text-2xl lg:text-lg md:px-6 lg:px-0 pb-7">
        {section?.heading}
      </p>
      <ul className=" container mx-auto flex flex-wrap lg:justify-between items-center justify-center lg:px-17 px-0 ">
        {section?.logos?.map((logo, index) => {
          let url = generateImageUrl(logo?.image?.asset?._ref).url();
          const { height, width } = getImageSize(logo?.image?.asset?._ref);
          const multiplier = logo?.is_retina ? 0.5 : 1;
          return (
            <SingleLogo
              key={index}
              url={url}
              alt={logo?.alt}
              width={width}
              height={height}
              multiplier={multiplier}
            />
          );
        })}
      </ul>
    </>
  );
};
const SingleLogo = ({ url, alt, width, height, multiplier }) => {
  return (
    <li className="text-center w-30 lg:w-auto py-7 lg:py-5 px-2.5 lg: px-0 lg:y-0">
      <figure>
        <img
          src={url}
          alt={alt}
          loading="lazy"
          width={width * multiplier}
          height={height * multiplier}
        />
      </figure>
    </li>
  );
};
export default Logos;
