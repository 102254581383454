import React from "react";

const ServicesCuttingEdge = ({ section }) => {
  return (
    <div className="container mx-auto text-center w-full xl:w-53">
      <h1 className="tracking-wide font-bold text-5xl leading-60 font-Dazzed lg:text-6xl lg:leading-80">
        {section.heading}
      </h1>
      <p className="font-medium text-2xl mx-3 mt-6 leading-32 font-Dazzed-medium lg:mx-20 lg:text-3xl">
        {section.description}
      </p>
    </div>
  );
};
export default ServicesCuttingEdge;
