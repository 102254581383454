import React, { useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";
import Spacer from "../../spacer";
import "./style.css";
const TrustExerciseReasonContent = ({ section, serializeContentEditor }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let ContentDiv = document.getElementsByClassName("content_blank");
      if (ContentDiv && ContentDiv.length) {
        for (let i = 0; i < ContentDiv.length; i++) {
          ContentDiv[i]
            .querySelectorAll("a")
            .forEach((a) => a.setAttribute("target", "_blank"));
        }
      }
    }
  });

  return (
    <>
      <div
        id="content_blank"
        className={`content_blank ${section?.bgBlack ? "bg-black" : ""}`}
      >
        <div
          className={`max-w-6xl mx-auto lg:px-16 lg:pt-24 pt-20 px-5 exercise-content ${
            section?.textWhite ? "text-primary-gray " : ""
          }`}
        >
          <h1 className="text-4xl font-bold w-full lg:w-3/5">
            {section?.title}
          </h1>
          <div className="flex flex-col lg:flex-row justify-between pt-12">
            <div className="w-full lg:w-45">
              <BlockContent
                blocks={section?.content?.content}
                serializers={serializeContentEditor(true)}
              />
            </div>
            {section?.content?.show_aside && (
              <div className="w-full lg:w-1/2 pt-12 lg:pt-0">
                <BlockContent
                  blocks={section?.content?.side_content}
                  serializers={serializeContentEditor(true)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`${section?.bottomSpace ? "hidden lg:block" : "hidden"} `}
        >
          <Spacer section={{ size: "m" }} />
        </div>
      </div>
    </>
  );
};
export default TrustExerciseReasonContent;
