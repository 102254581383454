import React from "react";
import { generateImageUrl } from "../../../sanity";

const WebsiteScreenshots = ({ content }) => {
  return (
    <>
      <div className="container mx-auto lg:px-17">
        <div className="border-b-2 border-light-yellow border-opacity-30">
          <h1 className="text-2.7 leadgin-54 font-bold text-center pt-24 pb-8 px-4 lg:px-0">
            {content?.title}
          </h1>
          {content?.images?.map((each, index) => (
            <div className="w-full mt-15 px-4 lg:px-0" key={index}>
              <div className="w-full flex justify-start items-center">
                <img
                  loading="lazy"
                  className="w-full"
                  src={generateImageUrl(each?.image?.asset?._ref).url() || ""}
                  alt="websiteScreenshot"
                />
              </div>
              <p className="text-black text-2xl py-4">{each?.title}</p>
              <p className="text-dark-beige text-xl">{each?.imageFormats}</p>
            </div>
          ))}
          <div className="flex justify-center py-12">
            <a
              className="text-primary-red underline hover:text-black hover:no-underline cursor-pointer w-max"
              target="_blank"
              rel="noopener noreferrer"
              href={`${content?.linkUrl}`}
            >
              {content?.linkText}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebsiteScreenshots;
