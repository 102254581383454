import React from "react";
const TrustDownloadFall = ({ section }) => {
  return (
    <>
      <div>
        <div className="max-w-4xl mx-auto px-5 lg:px-0">
          <div id="trustFallForm" className="w-100 w-70">
            <p className="lg:text-8xl text-4xl font-black">
              {section?.heading}
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-between pt-12 lg:pt-20">
            <div className="w-full lg:w-1/2">
              <p className="text-xl">{section?.leftDescription}</p>
            </div>
            <div className="w-100 lg:w-2/5 pt-8 lg:pt-0">
              <p className=" text-xl pb-8 font-bold">{section?.rightHeading}</p>
              <ul className="list-disc pl-8 text-lg">
                {section?.includeInFolder?.map((single, index) => (
                  <li className="pb-3" key={index}>
                    {single?.includeItems}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TrustDownloadFall;
