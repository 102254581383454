import React from "react";
import { dateFormatHandler } from "../../../utils";
import { Link } from "gatsby";
const Table = ({ section }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="text-center w-full lg:w-3/4 mx-auto px-3 lg:px-0">
          <h1 className="w-full text-3xl lg:text-5xl leading-32 lg:leading-54 font-bold pb-16 lg:pb-24">
            {section?.title || ""}
          </h1>
          {section?.events && section?.events?.length ? (
            <>
              {section?.events?.map((event, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col lg:flex-row justify-between border-b border-extra-dark-gray"
                  >
                    <div className="flex">
                      <div>
                        <h1 className="text-xl font-bold leading-32 py-4 text-left lg:text-center">
                          {dateFormatHandler(event?.date, true) || ""}
                        </h1>
                      </div>
                      <div>
                        <h1 className="text-xl leading-32 py-4 pl-8 lg:px-40 text-left lg:text-center">
                          {event?.Location || ""}
                        </h1>
                      </div>
                    </div>
                    {event.url ? (
                      <div>
                        {event?.url?.indexOf("http") !== -1 ? (
                          <a
                            href={event?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h1 className="text-xl font-bold leading-32 py-4 text-left lg:text-center pr-28 lg:pr-0">
                              {event?.title || ""}
                            </h1>
                          </a>
                        ) : (
                          <Link to={event?.url}>
                            <h1 className="text-xl font-bold leading-32 py-4 text-left lg:text-center pr-28 lg:pr-0">
                              {event?.title || ""}
                            </h1>
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div>
                        <h1 className="text-xl font-bold leading-32 py-4 text-left lg:text-center pr-28 lg:pr-0">
                          {event?.title || ""}
                        </h1>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <div>
              <p className="font-brother text-lg text-center">
                There are no upcoming events. Check back soon for updates!
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Table;
