import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import DecoratorEditor from "../../../assets/images/decoratorEditor.png";
import "./style.css";
const TwoColumnElement = ({
  section: { title, content, side_content, show_decorator },
  serializeContentEditor,
}) => {
  return (
    <>
      <div className="w-full lg:max-w-6xl mx-auto px-5 md:px-11 lg:px-0 twoColumnContentEditor relative">
        <div>
          <h3 className="tracking-wide leading-42 h-auto font-bold text-4xl font-Dazzed lg:text-7xl pr-16 lg:leading-80">
            {title}
          </h3>
          <div className="flex flex-col lg:flex-row justify-between pt-6 lg:pt-12">
            <div className="w-full lg:w-47">
              <BlockContent
                blocks={content}
                serializers={serializeContentEditor()}
              />
            </div>
            {side_content && (
              <div className="w-full lg:w-47">
                <BlockContent
                  blocks={side_content}
                  serializers={serializeContentEditor()}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {show_decorator && (
        <div className="hidden lg:block">
          <img
            className="absolute top-44"
            width="100%"
            src={DecoratorEditor}
            alt="decorator"
            loading="lazy"
          />
        </div>
      )}
    </>
  );
};
export default TwoColumnElement;
