import React from "react";
import ReactPlayer from "react-player/youtube";
const SizzleReel = ({ content }) => {
  return (
    <>
      <div className="container mx-auto lg:px-17">
        <div>
          <h1 className="text-2.7 leadgin-54 font-bold text-center pt-24 pb-8 px-4 lg:px-0">{content?.title}</h1>
          <div className="w-full mt-15 px-4 lg:px-0">
            {content?.videoLinks?.map((each, index) => (
              <div className="w-full lg:w-9/12 mx-auto h-64 lg:h-30 mx-auto" key={index}>
                <ReactPlayer className="youtube-player" style={{ width: "100%" }} url={each?.videoUrl || ""} />
              </div>
            ))}
            <div className="flex justify-center py-12">
              <a className="text-primary-red underline hover:text-black hover:no-underline cursor-pointer w-max" target="_blank" rel="noopener noreferrer" href={`${content?.linkUrl}`}>
                {content?.linkText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SizzleReel;
