import React from "react";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { restructorArray, dateFormatHandler } from "../../../utils";
import { Link } from "gatsby";

const WritingBody = ({ section }) => {
  const [splitedRecords, setSplitedRecords] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    setSplitedRecords(restructorArray(section?.contentBlock, 9));
  }, [section?.contentBlock]);

  const paginationHandler = (value) => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    setPageNumber(value.selected);
  };
  const linkReturnHandler = (link, title) => {
    if (link?.indexOf("http") !== -1) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={link}>
          {title}
        </a>
      );
    } else {
      return <Link to={link || ""}>{title || ""}</Link>;
    }
  };
  return (
    <>
      <div className="container mx-auto lg:px-17">
        <div className="w-full lg:w-10/12 mx-auto">
          <h1 className="text-2.7 leadgin-54 font-bold text-center pt-24 pb-8">
            {section?.heading || ""}
          </h1>
          <div className="mt-20 px-5 lg:px-0">
            {splitedRecords?.length &&
              splitedRecords[pageNumber]?.map((single, index) => (
                <div
                  className={`flex flex-col lg:flex-row justify-between w-full mb-16 pb-12 ${
                    index === splitedRecords[pageNumber]?.length - 1
                      ? "border-b-none lg:border-b-2 "
                      : "border-b-2 border-light-yellow border-opacity-30"
                  }`}
                  key={index}
                >
                  <div>
                    <p className="text-xl text-black pb-5 lg:pt-1">
                      {dateFormatHandler(single?.date, true, true)}
                    </p>
                  </div>
                  <div className="w-full lg:w-70">
                    <span className="text-2xl leading-40 font-semibold">
                      {linkReturnHandler(single?.link, single?.title)}
                    </span>

                    <p className="text-xl pt-4">{single?.description}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-center pt-12 lg:pt-24">
            {splitedRecords && (
              <ReactPaginate
                previousLabel={false}
                nextLabel={false}
                initialPage={splitedRecords?.pageNumber}
                pageCount={splitedRecords?.length}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={paginationHandler}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                disabledClassName={"paginationDisabled"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default WritingBody;
