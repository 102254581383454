import React from "react";
const DecoratorFive = () => {
  return (
    <>
      <div className="w-full">
        <svg
          width="100%"
          height="305"
          viewBox="0 0 1440 305"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.0847098"
            cx="1345.5"
            cy="246.5"
            r="47.5"
            transform="rotate(90 1345.5 246.5)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="1376"
            cy="60"
            r="44"
            transform="rotate(90 1376 60)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="1416"
            cy="104"
            r="25"
            transform="rotate(90 1416 104)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="1246"
            cy="165"
            r="29"
            transform="rotate(90 1246 165)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="1128"
            cy="89"
            r="29"
            transform="rotate(90 1128 89)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="3"
            cy="212"
            r="29"
            transform="rotate(90 3 212)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="538"
            cy="276"
            r="29"
            transform="rotate(90 538 276)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="338"
            cy="129"
            r="29"
            transform="rotate(90 338 129)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="743.5"
            cy="49.5"
            r="20.5"
            transform="rotate(90 743.5 49.5)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="485"
            cy="111"
            r="82"
            transform="rotate(90 485 111)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="155"
            cy="111"
            r="82"
            transform="rotate(90 155 111)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="682.5"
            cy="185.5"
            r="81.5"
            transform="rotate(90 682.5 185.5)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="853"
            cy="288"
            r="17"
            transform="rotate(90 853 288)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="1195.5"
            cy="266.5"
            r="38.5"
            transform="rotate(90 1195.5 266.5)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="338"
            cy="241"
            r="55"
            transform="rotate(90 338 241)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="938"
            cy="68"
            r="68"
            transform="rotate(90 938 68)"
            fill="#907236"
          ></circle>
          <circle
            opacity="0.0847098"
            cx="1006.5"
            cy="233.5"
            r="39.5"
            transform="rotate(90 1006.5 233.5)"
            fill="#907236"
          ></circle>
        </svg>
      </div>
    </>
  );
};
export default DecoratorFive;
