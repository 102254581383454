import React from "react";
import { generateImageUrl } from "../../../sanity";
import MobileDecorator from "../../../assets/images/servicesMobileDecorator.png";

const ServicesToolsPerform = ({ section }) => {
  return (
    <div className="relative">
      <div className="container mx-auto flex justify-between flex-col lg:flex-row px-3 lg:px-17">
        <div className="w-full lg:w-1/3">
          <h1 className="text-4xl font-Dazzed font-semibold leading-45">
            {section.heading}
          </h1>
        </div>
        <div className="flex flex-wrap justify-between w-full lg:w-56 xl:w-2/4">
          {section?.performing.map((single, index) => (
            <div
              key={index}
              className={`mt-6 mb-6 w-full  lg:mb-20 lg:mt-0 ${
                index % 2 === 0 ? "lg:w-2/5" : "lg:w-45"
              }`}
            >
              <h1 className="leading-28 text-2xl font-Dazzed font-semibold mt-4 lg:mt-0">
                {single.heading}
              </h1>
              <p className="text-lg opacity-50 text-black font-normal leading-28 font-brother mt-4">
                {single.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute top-0 left-0 lg:top-44 w-full mx-auto">
        <img
          className="hidden lg:block w-full mx-auto"
          src={generateImageUrl(section?.decoratorBg?.asset?._ref).url()}
          alt={section.decoratorBgalt}
          loading="lazy"
        />
        <img
          className="block lg:hidden w-full mx-auto"
          src={MobileDecorator}
          loading="lazy"
          alt="MobileDecorator"
        />
      </div>
    </div>
  );
};
export default ServicesToolsPerform;
