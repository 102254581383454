import React from "react";
import BlockContent from "@sanity/block-content-to-react";

const CourseDetailPerformanceList = ({ section }) => {
  let _performanceList = section?.performancelist?.filter(
    (item) => !item?.hidden
  );

  const serializeContentEditor = () => {
    const serializers = {
      types: {
        block: (props) => {
          switch (props.node.style) {
            case "headline":
              return (
                <h3 className="headline pb-16 font-bold text-3xl lg:text-4xl lg:leading-42">
                  {props.children}
                </h3>
              );
            case "smaller":
              return <p className="text-xl">{props.children}</p>;
            default:
              return <p className="text-2xl leading-32">{props.children}</p>;
          }
        },
      },
      marks: {
        highlight: (props) => {
          return (
            <span className="highlight text-3xl lg:text-4xl lg:leading-42 text-white lg:text-dark-beige font-bold font-Dazzed">
              {props.children}
            </span>
          );
        },
      },
      list: (props) => {
        return <ul className="text-lg ml-6 list-disc">{props?.children}</ul>;
      },
      listItem: (props) => {
        return <li className="mt-4">{props.children}</li>;
      },
    };
    return serializers;
  };
  return (
    <div className="container mx-auto">
      {_performanceList?.length ? (
        <div className="flex justify-between w-11/12 bg-medium-beige py-10  mx-auto rounded flex-wrap lg:w-70 lg:px-10">
          {_performanceList?.map((list, listIndex) => {
            return (
              <div
                key={listIndex}
                className={`w-11/12 mx-auto mb-12 lg:mb-0 ${
                  _performanceList?.length === 1 ? "w-full" : "lg:w-38"
                } `}
              >
                <h1 className="text-2xl font-semibold mb-4 lg:mb-6 min-h-50">
                  {" "}
                  {list.heading}
                </h1>
                {list?.list && (
                  <BlockContent
                    blocks={list?.list?.content}
                    serializers={serializeContentEditor()}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default CourseDetailPerformanceList;
