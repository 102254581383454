import React from "react";
import Star from "../../star";
const RatingTempleteFive = ({ detail, name, description,starsRight }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="w-full">
          <div className={`flex justify-start ${ starsRight? "justify-end lg:justify-start" : "justify-start" }`}>
            <div>
              <Star />
            </div>
            <div>
              <Star />
            </div>

            <div>
              <Star />
            </div>

            <div>
              <Star />
            </div>

            <div>
              <Star />
            </div>
          </div>
          <p className="leading-24 text-lg italic text-left py-5">
            {description}
          </p>
          <div className="text-left">
            <span className="leading-24 text-lg font-semibold italic">{name}</span>
            <span className="leading-24 text-lg italic ">{detail}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default RatingTempleteFive;
