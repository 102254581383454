import React from "react";
import { generateImageUrl } from "../../../sanity";
import ThreeCircles from "../decorators/threeCircles";
import SingleDecorator from "../../pageComponents/decorators/singleDecorator";
const TrustExerciseHero = ({ section }) => {
  let bgImage = generateImageUrl(section?.image?.image?.asset?._ref).url();

  const scrollContent = () => {
    let currentLocation =
      typeof window !== "undefined" && window?.location?.href;

    if (typeof window !== "undefined") {
      let item = document.getElementById(
        currentLocation?.includes("the-trust-fall")
          ? "trustFallForm"
          : currentLocation?.includes("leadership-and-teamability-assessment")
          ? "workbookPricing"
          : ""
      );
      item.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <>
      <div className="w-full relative">
        <div className="max-w-6xl mx-auto lg:px-16 flex justify-center lg:py-32 py-12 px-5 lg:px-0">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="w-full lg:w-1/2">
              <div className="absolute mx-auto lg:w-auto z-0 block lg:hidden">
                <ThreeCircles />
              </div>
              <h3 className="text-5xl py-5 font-black leading-54 lg:leading-64 lg:text-6xl">
                {section?.heroHeading}
              </h3>
              <p className="text-xl lg:text-lg pt-2">{section?.description}</p>

              <button
                onClick={() => scrollContent()}
                className="mt-8 text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-4 py-3 lg:px-2 xl:px-7 lg:py-4 rounded-full cursor-pointer"
              >
                Get started
              </button>
            </div>
            <div
              className="w-full rounded-md lg:w-4/12 rounded-md block lg:hidden mt-12"
              style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                minHeight: "557px",
              }}
            ></div>
            <div
              className="z-10 w-563 2xl:w-705 3xl:w-40 absolute top-0 right-0 rounded-md hidden lg:block"
              style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                minHeight: "700px",
              }}
            ></div>
          </div>
          <div className="absolute mx-auto lg:w-auto w-3/4 z-0 hidden lg:block">
            <svg
              width="641"
              height="553"
              viewBox="0 0 641 553"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.4">
                <circle
                  opacity="0.0839844"
                  cx="212.292"
                  cy="212.292"
                  r="212.292"
                  fill="#907236"
                />
                <circle
                  opacity="0.0839844"
                  cx="459.291"
                  cy="222.126"
                  r="181.634"
                  fill="#907236"
                />
                <circle
                  opacity="0.0839844"
                  cx="352.856"
                  cy="371.366"
                  r="181.634"
                  fill="#907236"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="w-26 absolute -top-40 -left-20 left-0 z-50 w-max">
          <SingleDecorator />
        </div>
      </div>
    </>
  );
};
export default TrustExerciseHero;
