import React from "react";
import Star from "../../star";
import { generateImageUrl, getImageSize } from "../../../../sanity";
const Rating = ({ section: { reviews, background_color } }) => {
  const returnClasses = (size) => {
    switch (size) {
      case "s":
        return "leading-32 lg:text-4xl lg:leading-50 ";
      case "m":
        return "leading-32 lg:text-6xl lg:leading-70";
      case "l":
        return "leading-32 lg:text-7xl lg:leading-70";
      default:
        return "";
    }
  };
  const startsHandler = (rating) => {
    let count = [];
    for (let i = 0; i <= rating - 1; i++) {
      count.push(i);
    }
    return count;
  };
  return (
    <div
      className={`w-full ${background_color ? "bg-primary-white py-12" : ""} `}
    >
      {reviews?.map((review, index) => {
        let url = review?.logo?.asset?._ref
          ? generateImageUrl(review?.logo?.asset?._ref).url()
          : "";
        const { height, width } = review?.logo?.asset?._ref
          ? getImageSize(review?.logo?.asset?._ref)
          : "";
        return (
          <div
            className={`w-full lg:w-3/4 mx-auto ${index !== 0 ? "pt-7" : ""} ${
              index !== reviews?.length - 1 ? "pb-7" : ""
            } `}
            key={index}
          >
            {!review?.hide_options?.hide_stars && (
              <div className="flex justify-center">
                {startsHandler(review?.rating).map((star, starIndex) => {
                  return <Star key={starIndex} />;
                })}
              </div>
            )}
            <h1
              className={`text-2xl text-center py-5 font-bold px-4 lg:px-0 ${returnClasses(
                review?.fontSize
              )}`}
            >{`“${review?.copy}”`}</h1>
            <p className="text-lg font-semibold text-center">
              {review?.author}
            </p>
            <p className="text-lg text-center">{review?.bio || ""}</p>
            {!review?.hide_options?.hide_logo && review?.logo?.asset?._ref ? (
              <figure className="flex items-center justify-center pt-7">
                <img
                  src={url}
                  alt=""
                  loading="lazy"
                  width={width}
                  height={height}
                />
              </figure>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default Rating;
