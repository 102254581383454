import React from "react";
import BuyTheBookListHardCover from "../BuyTheBookListHardCover";
import Spacer from "../../spacer";
import BuyTheBookForm from "../buyTheBookForm";
import { generateImageUrl } from "../../../sanity";
import BlockContent from "@sanity/block-content-to-react";
import "./style.css";
const HeroSection = ({ section, serializeContentEditor }) => {
  return (
    <>
      <div className="container mx-auto lg:px-17 px-0 buyPageHero">
        <div className="flex flex-col-reverse lg:flex-row justify-between px-6 lg:px-16 xl:px-0">
          <div className="left-side w-full lg:w-5/12">
            <BlockContent
              blocks={section?.leftContent}
              serializers={serializeContentEditor()}
            />
            {section?.bookVariants?.map((item, index) => {
              return (
                <div key={index}>
                  <Spacer section={{ size: "s" }} />
                  <BuyTheBookListHardCover item={item} />
                </div>
              );
            })}

            <Spacer section={{ size: "l" }} className="hidden lg:block" />
            <Spacer section={{ size: "s" }} className="block lg:hidden" />
            <BuyTheBookForm />
          </div>
          <div className="right-side pb-24 lg:pb-0">
            <img
              src={generateImageUrl(section?.rightImage?.asset?._ref).url()}
              alt="bookImage"
              loading="lazy"
            />
            <Spacer small />
            <div className="flex flex-col items-end">
              <h1 className="text-xl lg:text-2xl font-bold pb-8 pr-12 lg:pr-0">
                {section?.rightQuote}
              </h1>
              <div>
                <p className="text-xl pr-12 lg:pr-0">
                  — {section?.rightQuoteAuthor}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeroSection;
