import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import DecoratorEditor from "../../../assets/images/decoratorEditor.png";
import "./style.css";
const HomePageDescripiton = ({
  section: { content, show_aside, side_content, center_content },
  serializeContentEditor,
}) => {
  return (
    <>
      <div className="w-full lg:max-w-6xl mx-auto px-5 md:px-11 lg:px-0 flex flex-wrap homePageContent">
        <div
          className={`${
            center_content
              ? "w-full lg:w-46 mx-auto"
              : "w-full lg:w-70 pl-0 lg:pl-60 lg:pr-12"
          } `}
        >
          {!center_content && (
            <div className="hidden lg:block mb-9">
              <span className="px-2 border-b-2 border-dark-beige mb-9"></span>
            </div>
          )}
          <BlockContent
            blocks={content}
            serializers={serializeContentEditor()}
          />
        </div>
        {!center_content && (
          <div className="w-full lg:w-30 pl-0">
            {" "}
            {show_aside && !!side_content && (
              <BlockContent
                blocks={side_content}
                serializers={serializeContentEditor()}
              />
            )}
          </div>
        )}
      </div>
      <>
        {center_content && (
          <img
            className="py-12"
            width="100%"
            src={DecoratorEditor}
            alt="decorator"
            loading="lazy"
          />
        )}
      </>
    </>
  );
};
export default HomePageDescripiton;
