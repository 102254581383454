import React from "react";
const HeroImage = ({image}) =>{
    return(
        <>
        <div className="w-full h-52 lg:h-96" style={{
            backgroundImage: `url(${image})`,
            backgroundSize:"cover",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            minHeight:"100%",
            with:"100%",
        }}>

        </div>
        </>
    )
}
export default HeroImage;