import React from "react";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Spacer from "../../spacer";
import { restructorArray, usDateFormat } from "../../../utils";
import ReactPlayer from "react-player";
import { Link } from "gatsby";

const Media = ({ section, mediaFiles }) => {
  const [splitedRecords, setSplitedRecords] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [backupData, setBackupData] = useState([]);
  const [activeCategory, setActiveCategory] = useState();

  useEffect(() => {
    let _arr = [];
    if (
      section?.singleMedia &&
      section?.singleMedia?.length &&
    mediaFiles && 
      mediaFiles?.length
    ) {
      section?.singleMedia?.forEach((item) => {
        mediaFiles?.forEach((me) => {
          if (me?._id === item?._ref) {
            _arr.push(me);
          }
        });
      });
    }
    setBackupData(_arr);
    setSplitedRecords(restructorArray(_arr, 9));
  }, [mediaFiles, section?.singleMedia]);

  const paginationHandler = (value) => {
    if(typeof window !== "undefined"){
      window.scrollTo(0, 0)
    }
    setPageNumber(value.selected);
  };

  const filterRecord = (value) => {
    setActiveCategory(value);
    let splitCopy = [...backupData];
    if (value === "all") {
      setSplitedRecords(restructorArray(splitCopy, 9));
    } else {
      let FilteredItem = splitCopy?.filter(
        (item) => item.type.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
      FilteredItem = FilteredItem && restructorArray(FilteredItem, 9);

      setSplitedRecords(FilteredItem);
    }
    setPageNumber(0);
  };
  return (
    <div className="">
      <div className="container mx-auto py-24 lg:px-17">
        <h1 className="text-2.7 leadgin-54 font-bold text-center pb-8">
          Media
        </h1>
        <div className="flex justify-center container mx-auto">
          <ul className="flex">
            <li
              className={`mr-6 lg:mr-12 cursor-pointer hover:text-black hover:underline ${
                activeCategory === "all" || !activeCategory
                  ? "text-black underline"
                  : "text-dark-beige"
              }`}
            >
              <div
                onClick={() => {
                  filterRecord("all");
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  filterRecord("all");
                }}
              >
                All
              </div>
            </li>
            <li
              className={`mr-6 lg:mr-12 hover:text-black hover:underline cursor-pointer ${
                activeCategory === "article"
                  ? "text-black underline"
                  : "text-dark-beige"
              }`}
            >
              <div
                onClick={() => {
                  filterRecord("article");
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  filterRecord("article");
                }}
              >
                Articles
              </div>
            </li>
            <li
              className={`mr-6 lg:mr-12 hover:text-black hover:underline cursor-pointer ${
                activeCategory === "audio"
                  ? "text-black underline"
                  : "text-dark-beige"
              }`}
            >
              <div
                onClick={() => {
                  filterRecord("audio");
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  filterRecord("audio");
                }}
              >
                Audio
              </div>
            </li>
            <li
              className={`hover:text-black hover:underline cursor-pointer ${
                activeCategory === "video"
                  ? "text-black underline"
                  : "text-dark-beige"
              } `}
            >
              <div
                onClick={() => {
                  filterRecord("video");
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  filterRecord("video");
                }}
              >
                Video
              </div>
            </li>
          </ul>
        </div>
        <div className={`flex justify-between flex-wrap mt-20 px-5 lg:px-0`}>
          {splitedRecords?.length &&
            // eslint-disable-next-line array-callback-return
            splitedRecords[pageNumber]?.map((single, index) => {
              let url = single?.file;
              let bgImage = single?.bgImage?.url || "";
              if (single?.categoryType === "external") {
                return (
                  <div className="w-full lg:w-30 mb-16 mediaCard" key={index}>
                    <a
                      href={single?.externalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className="bg-cover bg-no-repeat bg-center h-48 relative"
                        style={{ backgroundImage: `url(${bgImage})` }}
                      ></div>
                      <h1 className="text-4xl leading-40 font-bold pt-5">
                        {single?.title}
                      </h1>
                    </a>
                    <p className="text-2xl pt-4">{single?.description}</p>
                    <p className="text-xl text-dark-beige pt-3">
                      {usDateFormat(single?.date, "line")}
                    </p>
                  </div>
                );
              } else {
                if (single?.type === "audio") {
                  return (
                    <div className="w-full lg:w-30 mb-16 mediaCard" key={index}>
                      <div
                        className={`bg-cover bg-no-repeat bg-top flex items-end h-48 w-full relative bg-black`}
                        style={{ backgroundImage: `url(${bgImage})` }}
                      >
                        <div className="absolute inset-0 bg-black opacity-50 flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-music"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#ffffff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="6" cy="17" r="3" />
                            <circle cx="16" cy="17" r="3" />
                            <polyline points="9 17 9 4 19 4 19 17" />
                            <line x1="9" y1="8" x2="19" y2="8" />
                          </svg>
                        </div>
                        <div
                          style={{ backgroundColor: "#ebe5dc" }}
                          className="h-12 relative w-full"
                        >
                          <ReactPlayer
                            className="absolute"
                            url={url}
                            width="100%"
                            height="100%"
                            playing={false}
                            controls={true}
                          />
                        </div>
                      </div>
                      <h1 className="text-4xl leading-40 font-bold pt-5">
                        {single?.title}
                      </h1>
                      <p className="text-2xl pt-4">{single?.description}</p>
                      <p className="text-xl text-dark-beige pt-3">
                        {usDateFormat(single?.date, "line")}
                      </p>
                    </div>
                  );
                } else if (single?.type === "video") {
                  return (
                    <div className="w-full lg:w-30 mb-16 mediaCard" key={index}>
                      <div className="bg-cover bg-no-repeat bg-center h-48	 relative">
                        <ReactPlayer
                          url={url}
                          width="100%"
                          height="100%"
                          playing={false}
                          controls={true}
                        />
                      </div>
                      <h1 className="text-4xl leading-40 font-bold pt-5">
                        {single?.title}
                      </h1>
                      <p className="text-2xl pt-4">{single?.description}</p>
                      <p className="text-xl text-dark-beige pt-3">
                        {usDateFormat(single?.date, "line")}
                      </p>
                    </div>
                  );
                } else if (single?.type === "article") {
                  return (
                    <div className="w-full lg:w-30 mb-16 mediaCard" key={index}>
                      <Link
                        to={`/article/${single?.title?.split(" ").join("-")}`}
                      >
                        <div
                          className="bg-cover bg-no-repeat bg-center h-48 relative"
                          style={{ backgroundImage: `url(${bgImage})` }}
                        ></div>
                        <h1 className="text-4xl leading-40 font-bold pt-5">
                          {single?.title}
                        </h1>
                      </Link>
                      <p className="text-2xl pt-4">{single?.description}</p>
                      <p className="text-xl text-dark-beige pt-3">
                        {usDateFormat(single?.date, "line")}
                      </p>
                    </div>
                  );
                }
              }
            })}
          <div className="w-full lg:w-30"></div>
        </div>

        <div className="flex justify-center">
          {splitedRecords && (
            <ReactPaginate
              previousLabel={false}
              nextLabel={false}
              initialPage={splitedRecords?.pageNumber}
              pageCount={splitedRecords?.length}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={paginationHandler}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              disabledClassName={"paginationDisabled"}
            />
          )}
        </div>
        <Spacer section={{ size: "l" }} />
      </div>
    </div>
  );
};
export default Media;
