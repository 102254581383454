import React from "react";
import "./style.css";
import BlockContent from "@sanity/block-content-to-react";
const Keynote = ({ serializeContentEditor, section }) => {
  return (
    <>
      <div className="container mx-auto boxedContent h-full w-full">
        <div className="w-full lg:w-3/5 mx-auto flex flex-col lg:flex-row justify-between items-center px-5 lg:px-16 bg-primary-gray px-9 pt-16 pb-24 h-full">
          <div className="pr-0 lg:pr-24 h-full w-full">
            <BlockContent
              blocks={section?.content}
              serializers={serializeContentEditor()}
            />
          </div>

          {section?.show_side_image && (
            <div className="mt-8 lg:mt-0">
              <BlockContent
                blocks={section?.side_image}
                serializers={serializeContentEditor()}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Keynote;
