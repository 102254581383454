import React from "react";
import RatingTempleteFive from "../ratings/ratingTempleteFive";
import Spacer from "../../spacer";
import BlockContent from "@sanity/block-content-to-react";
import "./style.css";
const BuyTheBookSucceed = ({ section, serializeContentEditor }) => {
  return (
    <>
      <div className="container mx-auto relative lg:px-16 px-0">
        <div className="px-6 lg:px-16 xl:px-0">
          <h1 className="hidden lg:block text-5xl leadgin-42 lg:text-116 font-black font-Dazzed lg:leading-110">{section?.title || ""}</h1>
          <Spacer section={{ size: "m" }} className="hidden lg:block" />
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="pl-0 lg:pl-56">
              <Spacer section={{ size: "m" }} className="block lg:hidden" />
              <h1 className=" block lg:hidden text-5xl leadgin-42 lg:text-116 font-black font-Dazzed lg:leading-110">{section?.title || ""}</h1>
              <Spacer section={{ size: "s" }} className="block lg:hidden" />
              <div className="container  buyPageContent">
                <BlockContent blocks={section.content} serializers={serializeContentEditor()} />
              </div>
            </div>
            <div className="buyRatings pl-0 lg:pl-24 lg:pt-80 pt-0 w-full lg:w-5/6">
              <h1 className="ratingDecorator font-Dazzed hidden lg:block text-2xl leadgin-21 font-black mb-20 italic relative">{section?.reviewsTitle || ""}</h1>
              {section?.reviews?.map((review, index) => {
                return (
                  <div key={index}>
                    <RatingTempleteFive description={review?.copy} name={review?.author + ", "} detail={review?.bio || " "} />
                    <Spacer section={{ size: "s" }} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 lg:-left-12 lg:top-80">
          <span>
            <svg className="w-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 1467">
              <circle fill="rgba(144,114,54,.06)" cx="246.5" cy="95.5" r="47.5"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="60" cy="65" r="44"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="104" cy="25" r="25"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="165" cy="195" r="29"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="89" cy="313" r="29"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="212" cy="1438" r="29"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="276" cy="903" r="29"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="129" cy="1103" r="29"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="49.5" cy="697.5" r="20.5"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="111" cy="956" r="82"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="111" cy="1286" r="82"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="185.5" cy="758.5" r="81.5"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="288" cy="588" r="17"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="266.5" cy="245.5" r="38.5"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="241" cy="1103" r="55"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="68" cy="503" r="68"></circle>
              <circle fill="rgba(144,114,54,.06)" cx="233.5" cy="434.5" r="39.5"></circle>
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};
export default BuyTheBookSucceed;
