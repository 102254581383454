import React from "react";
import "./style.css";
const WhatsYourGoal = ({ section }) => {
  const LinkReturnHandler = ({ link, text }) => {
    return (
      <a
        className="cursor-pointer m-0 leading-0"
        target={
          link?.indexOf("http:") !== -1 || link?.indexOf("https:") !== -1
            ? "_blank"
            : "_self"
        }
        rel="noopener noreferrer"
        href={link}
      >
        <div>
          <p className="list-after text-base pr-3 pt-3 m-0 leading-0 text-left after:content-['_↗'] ">
            {text}
          </p>
        </div>
      </a>
    );
  };

  return (
    <>
      <div className="container mx-auto text-center">
        <h2 className="tracking-wide font-bold text-5xl leading-60 font-Dazzed lg:text-6xl lg:leading-80 pb-20">
          {section?.mainHeading}
        </h2>
        <div className="w-full lg:max-w-5xl mx-auto px-5 lg:px-16 xl:px-0 flex flex-col lg:flex-row justify-between flex-wrap">
          {section?.goals?.map((goal, goalIndex) => {
            return (
              <div
                className="goal-card w-full lg:w-31 transition duration-500 ease-in-out pl-6 pr-10 pt-6 pb-8 bg-extra-dark-gray rounded-md mt-8 relative text-black hover:text-white hover:bg-primary-red"
                key={goalIndex}
              >
                <>
                  <div className="goal-heading-outer">
                    <h3 className="goal-heading leading-32 text-2xl font-bold pt-8 text-left pr-12">
                      {goal?.heading}
                    </h3>
                  </div>
                  <span className="hover-arrow absolute right-8 bottom-8">
                    <svg
                      className="arrow"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fillcurrent="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 12H22.5M22.5 12L12 1.5M22.5 12L12 22.5"
                        stroke="#101828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </>
                <div className="absolute bottom-2 pt-12">
                  {goal?.listGoal?.map((list, listIndex) => {
                    return (
                      <div className="goal-card-inner my-2" key={listIndex}>
                        <LinkReturnHandler
                          link={list?.link}
                          text={list?.goalItem}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default WhatsYourGoal;
