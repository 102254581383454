import React from "react";

const ThreePointFeatureBlock = ({ section }) => {
  const LinkReturnHandler = ({ link, text }) => {
    return (
      <a
        className="cursor-pointer leading-0 text-dark-gray-extra text-lg"
        target={
          link?.indexOf("http:") !== -1 || link?.indexOf("https:") !== -1
            ? "_blank"
            : "_self"
        }
        rel="noopener noreferrer"
        href={link}
      >
        {text}
      </a>
    );
  };
  return (
    <>
      <div className="bg-primary-white py-12">
        <div className="container mx-auto">
          <div className="w-full lg:w-11/12 mx-auto px-5 lg:px-0 flex flex-col lg:flex-row flex-wrap justify-between items-start">
            {section?.featureBlocks?.map((single, index) => {
              return (
                <div
                  className="w-full lg:w-31 text-center flex flex-col items-center mt-12"
                  key={index}
                >
                  <div
                    style={{ height: "50px", width: "50px" }}
                    className="bg-dark-gray-extra rounded-full text-light-beige font-bold text-xl flex items-center justify-center"
                  >
                    {index + 1}
                  </div>
                  <a
                    className={`${
                      single?.button?.link
                        ? "pointer-events-auto"
                        : "pointer-events-none"
                    }`}
                    target={
                      single?.button?.link?.indexOf("http:") !== -1 ||
                      single?.button?.link?.indexOf("https:") !== -1
                        ? "_blank"
                        : "_self"
                    }
                    href={single?.button?.link}
                  >
                    <h1 className="text-2xl  lg:text-3xl lg:leading-38 font-bold text-center pt-4">
                      {single?.heading}
                    </h1>
                  </a>
                  <p className="pt-3 text-lg mb-7 font-medium leading-24">
                    {single?.description}
                  </p>

                  <div>
                    <LinkReturnHandler
                      link={single?.button?.link}
                      text={single?.button?.text}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default ThreePointFeatureBlock;
