import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import "./style.css";

const CourseDetailHero = ({
  section: { content, headingOne, headingTwo, mainheading },
  serializeContentEditor,
}) => {
  return (
    <div className="container mx-auto w-11/12 lg:w-47 pt-32">
      <h3 className="headline font-bold text-4xl lg:text-6xl lg:leading-42 opacity-50 pb-0 lg:pb-4">
        {mainheading}
      </h3>
      <h3 className="headline font-bold text-4xl lg:text-6xl pb-3 lg:pb-0">
        {headingOne}
      </h3>
      <h3 className="text-dark-beige text-2xl lg:text-3xl font-bold opacity-50 pb-8 pt-4">
        {headingTwo}
      </h3>
      <div>
        <BlockContent
          className="course-detail-hero-content"
          blocks={content}
          serializers={serializeContentEditor()}
        />
      </div>
    </div>
  );
};
export default CourseDetailHero;
