import React from "react";
import { generateImageUrl } from "../../../sanity";
import LiveTag from "../../../assets/images/liveTag.png";
import Clock from "../../../assets/images/clock.png";
import Calendar from "../../../assets/images/calendar.png";
import Location from "../../../assets/images/location.png";
import Avatar from "../../../assets/images/avatar.png";
import moment from "moment-timezone";

const LiveTime = ({ section }) => {
  let url = section?.liveMember?.image?.asset?._ref
    ? generateImageUrl(section?.liveMember?.image?.asset?._ref).url()
    : null;
  return (
    <>
      <div className="container mx-auto boxedContent h-full w-full">
        <div className="w-full lg:w-70 mx-auto px-5 lg:px-16 bg-primary-gray px-9 pt-4 pb-6">
          <div className="flex flex flex-col lg:flex-row justify-between items-center w-full">
            <div className="w-full lg:w-45">
              <div className="pt-8 pb-14">
                <img width={80} src={LiveTag} alt="Tag" />
              </div>
              <div className="pb-8 flex items-center">
                <img width={27} src={Calendar} alt="calendar" />
                <p className="pl-8 text-light-black font-medium leading-28 text-lg">
                  {}
                  {moment(section?.dateAndTime?.datetime).format(
                    "dddd, MMMM Do"
                  )}
                </p>
              </div>
              <div className="pb-8 flex items-center">
                <img width={27} src={Clock} alt="clock" />
                <p className="pl-8 text-light-black font-medium leading-28 text-lg">
                  {moment(section?.dateAndTime?.datetime).format("HH:mm a") +
                    "  " +
                    section?.dateAndTime?.timezone}
                </p>
              </div>
              <div className="pb-8 flex items-center">
                <img width={23} src={Location} alt="location" />
                <p className="pl-8 text-light-black font-medium leading-28 text-lg">
                  {section?.liveOn}
                </p>
              </div>
            </div>
            <div className="w-full lg:w-45 pt-7 lg:pt-0">
              <div className="flex items-center">
                {url ? (
                  <div
                    className="w-14 h-14 rounded-full"
                    style={{
                      backgroundImage: `url(${url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      minHeight: "100%",
                      with: "100%",
                    }}
                  ></div>
                ) : (
                  <img width={40} src={Avatar} alt="avatar" loading="lazy" />
                )}
                <div className="pl-5">
                  <p className="font-bold text-base pb-1">
                    {section?.liveMember?.name}
                  </p>
                  <p className="text-sm">{section?.liveMember?.description}</p>
                </div>
              </div>
              <div>
                <p className="text-light-black font-semibold leading-28 pt-7 font-medium text-lg pb-3">
                  {section?.shortDescripiton}
                </p>
              </div>
              <a
                href={section?.button?.btnLink}
                className="bg-primary-red hover:bg-black text-white transition duration-300 ease-in-out px-6 py-3 rounded-full cursor-pointer"
              >
                {section?.button?.btnText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LiveTime;
